import React, { useEffect } from 'react';
import { Answer } from '../pages/Game';
import "./ClueBoard.css";
import { Clue } from './ClueScroller';
import { createDiagonalGradient } from '../Util';
import { useTheme } from '@mui/material';
import { IMO_PROXY } from '../Constants';

export type ColorDot = {
    color: string;
    cellIndex: number;
    direction: string;
};

interface Props {
    clues: Clue[] | undefined;
    answers: Answer[];
    clueSet: number;
    onClueSelect: (clue: Clue, index: number) => void;
    selectedCell: number;
    onCellSelect: (cell: number) => void;
    selectedColor: string;
    dots: ColorDot[];
}

interface ClueProps {
    clue: Clue;
    answer: Answer[];
    selected: boolean;
    onSelect: (clue: Clue) => void;
    selectedCell: number;
    onCellSelect: (cell: number) => void;
    selectedColor: string;
    dots: ColorDot[];
}

const ClueBoard = ({clues, answers, clueSet, onClueSelect, selectedCell, onCellSelect, selectedColor, dots}: Props) => {
    if (!clues || !answers) {
        return (
            <div className={"clueboard-container"}>
                <div className={"clueboard-loading"}>No clues available</div>
            </div>
        )
    }
    return (
        <div className={"clueboard-container"}>
            {
                clues.map((clue, index) => 
                    <ClueRow 
                        key={`clue-${index}-${clue.direction}`} 
                        clue={clue} 
                        answer={answers} 
                        selected={index === clueSet} 
                        onSelect={(c) => onClueSelect(clue, index)}
                        selectedColor={selectedColor}
                        selectedCell={selectedCell}
                        onCellSelect={(cell) => {
                            onClueSelect(clue, index);
                            onCellSelect(cell);
                        }}
                        dots={dots}
                    />
                )
            }
        </div>
    );
}


const ClueRow = ({clue, answer, selected, onSelect, selectedCell, onCellSelect, selectedColor, dots}: ClueProps) => {
    useEffect(() => {
        if (selected) {
            const el = document.getElementById(`clue-${clue.direction}-${clue.label}`);
            if (el) {
                el.scrollIntoView({behavior: "smooth"});
            }
        }
    }, [selected]);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const style = isDarkMode ? {background: "#202020", border: "1px solid black"} : {};


    const renderFormattedClue = (clue: Clue) => {
        return (
            <div dangerouslySetInnerHTML={{__html: clue.text[0].formatted?.replaceAll("https://", `${IMO_PROXY}https://`) || clue.text[0].plain}}>
            </div>
        )
    };


    return (
        <div 
            id={`clue-${clue.direction}-${clue.label}`}
            className={"clue-row"} 
            style={selected ? {background: selectedColor, color: "black"} : {...style}} 
            onClick={() => onSelect(clue)}
        >
            <div className={"clue-row-dots"}>
                {
                    dots.map((dot, index) =>
                        <div key={`clue-row-dot-container-${index}`}>
                            {clue.cells.indexOf(dot.cellIndex) > -1 && 
                             clue.direction.toLowerCase() === dot.direction.toLowerCase() &&
                            <div 
                                className={"clue-row-dot"} 
                                style={{backgroundColor: dot.color}} 
                                key={`clue-row-dot-${index}`} 
                            />}
                        </div>
                    )
            }
            </div>
            <div className={"clue-row-label"}>{`${clue.label}${clue.direction.charAt(0)}: `}
                {renderFormattedClue(clue)}
            </div>
            <div className={"clue-row-answer"}>
                {
                    clue.cells.map((cell, index) => 
                        <AnswerCell 
                            sx={{borderLeft: index === 0 ? "2px solid black" : "none"}}
                            key={`answer-${index}`} 
                            answer={answer[cell]} 
                            isSelected={cell === selectedCell}
                            onSelect={() => onCellSelect(cell)}
                            dots={dots.filter((dot) => dot.cellIndex === cell)}
                            color={selectedColor}
                        />
                    )
                }
            </div>
        </div>
    )
}

const AnswerCell = ({answer, isSelected, sx, onSelect, dots, color}: 
        {answer: Answer | undefined, 
        isSelected: boolean, 
        sx: any, onSelect: () => void, 
        dots: ColorDot[],
        color: string
    }) => {

    const background = dots.length > 0 ? createDiagonalGradient(dots.map((dot) => dot.color), "80") : "white";
    // if (background !== "white")  console.log(background);
    const style = {
        ...sx,
        background: isSelected ? `${color}` : background,
        color: answer?.isPencil ? "#c0c0c0" : answer?.color || "black",
        fontStyle: answer?.isPencil ? "italic" : "normal",
    }
    return (
        <div 
            className={"clue-row-answer-cell"} 
            style={style}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onSelect();
            }}
        >
            {answer ? answer.letter : ""}
        </div>
    )
}

export default ClueBoard;