
import "./Loader.css";
import load from "../assets/loading.gif";

const Loader = () =>{
    return (
      <div className="loader-container">
        <img src={load} alt="Loading..." />
      </div>
    );
  }
  
export default Loader;
  