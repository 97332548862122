import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { createContext, useContext, useState } from "react";

interface Props {
    children: React.ReactNode;
}

type AlertContextType = {
    alert: (alert: AlertType) => void;
};

type AlertType = {
    message: string,
    onConfirmCallback: () => void,
    onCancelCallback: () => void,
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);


const AlertProvider = ({ children }: Props) => {
    const [open, setOpen] = useState(false);
    const [alertData, setAlertData] = useState<AlertType>({
        message: "Crosswords Alert Provider Error",
        onConfirmCallback: () => {},
        onCancelCallback: () => {},
    });

    const alert = (alert: AlertType) => {
        setAlertData(alert);
        setOpen(true);
    };

    return (
        <AlertContext.Provider value={{ alert }}>
            {children}
            <Dialog
                open={open}
                onClose={() => {
                    alertData.onCancelCallback && alertData.onCancelCallback();
                    setOpen(false);
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        {alertData.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        alertData.onCancelCallback && alertData.onCancelCallback();
                        setOpen(false);
                    }}>Cancel</Button>
                    <Button 
                        onClick={() => {
                            alertData.onConfirmCallback && alertData.onConfirmCallback();
                            setOpen(false);
                        }}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </AlertContext.Provider>
    );
};

export default AlertProvider;

export const useAlerts = () => {
    const context = useContext(AlertContext);
    if (context === undefined) {
        throw new Error("useAlerts must be used within a AlertProvider");
    }
    return context;
}
