import './UserBadge.css';
import { IoShieldCheckmark } from "react-icons/io5";
import { GiNightSleep } from "react-icons/gi";

interface Props {
    username: string,
    isOnline: boolean,
    color: string,
    isSelf: boolean, // If the user is the current user
    isVerified?: boolean,
}

const UserBadge = ({username, isOnline, color, isSelf, isVerified = false} :Props) => {
    return (
        <div className={"user-badge"}>
            <div className="social-bar-user-color" style={isOnline ? {backgroundColor: color} : {}}>

                {isOnline ? "" : <GiNightSleep style={{fill: color}}/>}
            </div>
            {isOnline ? <div className="online-dot" style={isVerified ? {background: "white"} : {}}></div> : null}
            <div style={isSelf ? {fontWeight: "bold"} : {}} className="social-bar-user-label">
                {isVerified && isOnline ? 
                    <IoShieldCheckmark className="user-badge-verified" style={{fill: "#53af4d"}} />
                    : null}
                {username} 
            </div>
        </div>
    )

}

export default UserBadge;