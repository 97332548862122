import { Button, Input, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import "./Home.css";
import { VERSION } from "../Version";
import Logo from "../components/Logo";
import InstallInfo from "../components/InstallInfo";
import { LuRefreshCw } from "react-icons/lu";
import { requireInstall } from "../Util";
import { useNavigate } from "react-router-dom";

const Home = () =>{
  const [room, setRoom] = useState<string>(localStorage.getItem("cw-room") || "");
  const [username, setUsername] = useState<string>(localStorage.getItem("username") || "");
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const navigate = useNavigate();

  const enterRoom = () => {
    localStorage.setItem("cw-room", room);
    // window.location.href = `/${room}?version=${VERSION}`;
    navigate(`/${room}?version=${VERSION}`);
  }

  useEffect(() => {
    if (username && username.length > 0) {
      localStorage.setItem("username", username);
    }
  }, [username]);


  if (requireInstall()) return <InstallInfo />;

  return (
    <div className="home-container">
      <Logo width={150} height={150} fill={isDarkMode ? "#ffffff": "#000000"}/>
      <h2 style={{textAlign: "center"}}>Enter a room name to play</h2>

      {/* <Input
        placeholder="Username {optional}"
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      /> */}
      <div>
        <Input 
          placeholder="Room Name" 
          onChange={(e) => setRoom(e.target.value.toLowerCase().replaceAll(" ", "_"))}
          value={room}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              enterRoom();
            }
          }}
          endAdornment={
            <Button 
              onClick={() => {
                enterRoom();
              }}
            >Enter</Button>
          }
        />
      </div>
      <div className={"version-info"}>
        Version {VERSION}
        <br/>
        <div
          className={"refresh-button"}
          onClick={() =>{
            // @ts-ignore
            window.location.reload(true);
          }}
        >
          <LuRefreshCw />
          click here to refresh
        </div>
      </div>
    </div>
  );
}

export default Home;
