import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "../AxiosClient";


const getGameByName = (name: string) => {
    const promise = axiosClient.get(`/game?d=${name}`);
    return promise;
}


export const useGame = (name: string) => {
    return useQuery(['game', name], () => getGameByName(name), {
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
    });
}