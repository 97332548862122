import { useEffect, useState } from "react";
import {BiSolidLeftArrow, BiSolidRightArrow} from "react-icons/bi";
import "./ClueScroller.css";
import { useTheme } from "@mui/material";
import { IMO_PROXY } from "../Constants";
import { findClueAddressesWithLists, findClueByAddress } from "../Util";

interface Props {
    clues: Clue[],
    onClueSelect: (cells: number[], clueIndex: number) => void,
    clueSet?: number,
    onNext?: () => void,
    onPrevious?: () => void,
    onTap?: () => void,
}

export type Clue = {
    cells: number[],
    direction: string,
    label: string,
    text: [
        {
            formatted?: string,
            plain: string,
        }
    ]
};

const ClueScroller = ({clues, onClueSelect, clueSet = 0, onNext = () => {}, onPrevious = () => {}, onTap = () => {}} : Props) => {
    const [fontSize, setFontSize] = useState<number>(100);
    const [selectedClue, setSelectedClue] = useState<number>(0);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    useEffect(() => {
        if (clueSet) {
            // find and remove all elements with class "selected-clue"
           
        }
    }, [clueSet]);

    const updateTextSize =() => {
        const container = document.querySelector('.clue-scroller-text');
        const text = document.querySelector('.clue-scroller-p');
        const containerWidth = container?.clientWidth;
        const textWidth = text?.scrollWidth;
   
        if (containerWidth && textWidth) {
            const fontSizePercentage = (containerWidth / textWidth) * 100;
            setFontSize(fontSizePercentage);
        }

    }

    const renderFormattedClue = (clue: Clue) => {
        return (
            <>
                <div dangerouslySetInnerHTML={{__html: clue.text[0].formatted?.replaceAll("https://", `${IMO_PROXY}https://`) || clue.text[0].plain}}>
                </div>
                {
                    findClueAddressesWithLists(clue.text[0].plain).map((address, index) => {
                        const found = findClueByAddress(clues, address.clueLabel, address.direction);
                        if (!found) return <></>;
                        return (
                            <fieldset
                                key={`clue-sub-${index}`} 
                                id={`clue-sub-${index}`} 
                                className={"clue-sub"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onClueSelect(found.cells, found.index);
                                }}
                            >
                                <legend>{address.clueLabel} {address.direction}</legend>
                                {found.label} {found.clue.text[0].plain}
                            </fieldset>
                        )
                    })
                }
            </>
        )
    };

    const renderClues = () => {
        const across = [];
        const down = [];
        for (let index = 0; index < clues.length; index++) {
            const clue = clues[index];
            const element = (
                <div 
                    key={`clue-${index}`} 
                    id={`clue-${index}`} 
                    className={"clue"}
                    onClick={() => onClueSelect(clue.cells, index)}
                >
                    {clue.label} {clue.text[0].plain}
                </div>
            );
            if (clue.direction.toLowerCase() === "across") {
                across.push(element);
            } else {
                down.push(element);
            }
        }
        return (
            <div className="clue-scroller" style={isDarkMode ? {background: "#242424"} : {}}>
                <div 
                    className="clue-button"
                    onClick={() => onPrevious()}
                >
                    <BiSolidLeftArrow />
                </div>
                <div className="clue-scroller-text" onClick={() => onTap()}>
                    <p className="clue-scroller-p" style={{fontSize: `${fontSize}%`}}>
                        {renderFormattedClue(clues[clueSet])}
                        {/* {clues[clueSet].text[0].formatted ? renderFormattedClue(clues[clueSet]) : clues[clueSet].text[0].plain} */}
                    </p>
                </div>
                <div 
                    className="clue-button"
                    onClick={() => onNext()}
                >
                    <BiSolidRightArrow />
                </div>
            </div>
        );
    }

    return renderClues();

}

export default ClueScroller;