import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "../AxiosClient";


const getChatHistory= (name: string) => {
    const promise = axiosClient.get(`/game/chathistory?room=${name}`);
    return promise;
}


export const useChatHistory= (name: string) => {
    return useQuery(['chat_history', name], () => getChatHistory(name), {});
}