import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "../AxiosClient";


const getLatestVersion= () => {
    const promise = axiosClient.get(`/game/uiversion`);
    return promise;
}


export const useLatestVersion = () => {
    return useQuery(['version'], () => getLatestVersion(), {
        refetchInterval: 1000 * 60 * 1, // 10 minutes
    });
}