import './InstallInfo.css';
import Logo from './Logo';

interface Props {
    roomName?: string,
}

const InstallInfo = ({roomName} : Props) => {

    return (
        <div className="install-info">
            <div className="install-info-text">
                <div className="install-info-logo">
                    <Logo width={100} height={100} fill={"#ffffff"} />
                </div>
            
                <div className="install-info-title">Install Crosswords</div>
                { roomName &&
                    <div className="install-info-subtitle">{`You're invited! To join this crossword, install Crosswords PWA and enter the room: `}
                    <span className="room-name">{roomName}</span></div>
                }
            </div>
            <div className="install-info-instructions">
                In Safari on iOS, tap the share button and select "Add to Home Screen"
                <br/>
                <br/>
                In Chrome on Android, tap the menu button and select "Add to Home Screen"
            </div>
        </div>
    )
}

export default InstallInfo;