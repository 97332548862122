import { useLoaderData } from "react-router-dom";
import { useGame } from "../hooks/useGame";
import Loader from "../components/Loader";
import Board from "../components/Board";
import Clues from "../components/Clues";
import "./Game.css";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLive } from "../hooks/useLive";
import KeyboardReact from "react-simple-keyboard";
import 'react-simple-keyboard/build/css/index.css';
import { addCrossOrigin, findClueAddresses, findOverlay, getUUID, isMobile, requireInstall } from "../Util";
import ClueScroller, { Clue } from "../components/ClueScroller";
import SocialBar from "../components/SocialBar";
import Error from "../components/Error";
import {CgSearch, CgSearchLoading} from "react-icons/cg";
import { BsUiChecksGrid } from "react-icons/bs";
import { FaFlagCheckered } from "react-icons/fa";
import { ImInfo } from "react-icons/im";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Snackbar, TextField, useTheme } from "@mui/material";
import { LuSettings2 } from "react-icons/lu";
import { VscWholeWord } from "react-icons/vsc";
import ChatRoom from "../components/ChatRoom";
import InstallInfo from "../components/InstallInfo";
import { useChatHistory } from "../hooks/useChat";
import Confetti from "react-confetti";
import ClueBoard from "../components/ClueBoard";
import { VERSION } from "../Version";
import { useNavigate } from "react-router-dom";
import { useAlerts } from "../providers/AlertProvider";
import { useSettings } from "../providers/SettingsProvider";
import { KEYBOARD_LAYOUTS } from "../Constants";
import { type } from "os";

interface Params {
  params: {
    game: string;
  }
}

interface Props {
  date: string;
  listView: boolean;
}

export type Answer = {
  letter: string,
  timestamp: string,
  isPencil: boolean,
  color: string,
  comment?: AnswerComment,
}

export type AnswerComment = {
  author: string,
  text: string,
  timestamp: string,
  color: string,
  type: "comment" | "pin"
}

export type Chat = {
  username: string,
  message: string,
  timestamp: string,
  type: string,
  color?: string,
  userId?: string,
  clueAttachments?: ClueAttachment[],
  isSilent?: boolean,
  fontIndex?: number,
  verified?: boolean
}

export type ClueAttachment = {
  clueLabel: string,
  direction: string,
  clueIndex: number,
  text: {
    formatted?: string,
    plain: string,
  },
  clue: Clue,
}

export async function gameLoader ({ params } : Params) {
  const game = await params.game;
  return { game };
}

//const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
const iOS = isMobile(); // I know, iOS is not the only mobile OS, but it's the only one I care about right now
let screenLock: any = undefined;
let wakeLock:any = null;
const Game = ({date, listView}: Props) => {
  const { game }: any = useLoaderData();
  const settings = useSettings();
  const { alert } = useAlerts();
  // const version  = useLatestVersion();
  const getChatHistory = useChatHistory(`${game}-${date}`);
  const [hasWon, setHasWon] = useState<boolean>(false);

  const [rebusModalOpen, setRebusModalOpen] = useState<boolean>(false);
  const [rebusInput, setRebusInput] = useState<string>("");
  // Pencil override is from the mobile keyboard
  const [pencilOverride, setPencilOverride] = useState<boolean>(false);

  // Data contains information that is being passed through the socket
  const [data, setData] = useState<any>({});
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [uuid, setUuid] = useState<string>(getUUID());
  // const [username, setUsername] = useState<string>(localStorage.getItem("username") || randomName());
  // const [color, setColor] = useState<string>(localStorage.getItem("color") || randomColor());

  // Current cells for clue
  const [cells, setCells] = useState<number[]>([0]);
  // current clue index
  const [clueIndex, setClueIndex] = useState<number>(0);
  // Current Clue Direction
  const [clueDirection, setClueDirection] = useState<string>("across");

  const [cellPointer, setCellPointer] = useState<number>(0);
  const [answers, setAnswers] = useState<Answer[]>([]);
  

  const [updated, setUpdated] = useState<number>(0);
  const [rx, setRx] = useState<any>({});

  const [initialDataReceived, setInitialDataReceived] = useState<any>(undefined);

  // Chat room states
  const [chatActive, setChatActive] = useState<boolean>(false); // keep track of chat focus for window listeners
  const [chatHistory, setChatHistory] = useState<Chat[]>([]); // keep track of chat messages
  const [chatRx, setChatRx] = useState<Chat | undefined>(undefined);
  const [chatRoomOpen, setChatRoomOpen] = useState<boolean>(false);

  const [newMessagesWhileClosed, setNewMessagesWhileClosed] = useState<number>(0);

  const [visibility, setVisibility] = useState<boolean>(true);

  const theme = useTheme();
  const navigate = useNavigate();

  const isDarkMode = theme.palette.mode === 'dark';


  // game data is the data that is loaded from the server, basically the puzzle with clues
  const gameData = useGame(date);

  // Socket connection with callbacks to set data (setRx) and a pointer to the socket (setSocket)
  useLive(`${game}-${date}`, 
          uuid, 
          (d: any) => setRx(d),  // puzzle data handler
          (s: WebSocket) => setSocket(s), // socket connection handler
          (u: string[]) => setOnlineUsers(u), // user status handler
          (d: Chat) => setChatRx(d) // chat message handler
        );

  useEffect(() => {
    // when the settings is closed, commit the changes by sending the updated data structure
    if(!settings.open) sendCells(cells);
  }, [settings.open]);

  // Check to see if won.  Throw confetti if so
  // useEffect(() => {
  //   checkAnswers(undefined, false, true);
  // }, [data]);


  // useEffect(() => {
  //   if (!version.isLoading && !version.isFetching && version.data) {
  //     if (version.data?.data?.version && version.data?.data?.version !== VERSION && version.data?.data?.version !== "0.0.0") {
  //       // @ts-ignore
  //       window.location.reload(true);
  //     }
  //   }
  // }, [version.isLoading, version.isFetching]);

  /**
   * When chat history is loaded, update the chat history state 
   */
  useEffect(() => {
    if(getChatHistory.data && getChatHistory.isFetched) {
      const newHistory: Chat[] = []; 
      getChatHistory.data.data.messages.forEach((m: Chat) => {
        m.userId = m.username;
        m.color = data[m.userId]?.color || "#ababab";
        m.username = data[m.userId]?.username || "Unknown user";
        m.fontIndex = data[m.userId]?.fontIndex || 0;
        m.clueAttachments = [];
        m.verified = data[m.userId]?.verified || false;
        // @ts-ignore
        m.isSilent = (m.isSilent === "true");

        const clueAttachments = findClueAddresses(decodeURI(m.message));
        clueAttachments.forEach((address) => {
          const found = findClueByAddress(address.clueLabel, address.direction);
          if (found) {
            m.clueAttachments?.push({
              clueLabel: address.clueLabel,
              direction: address.direction,
              clueIndex: found.index,
              text: found.clue.text[0] || "",
              clue: found.clue,
            });
          }
        });
        if (!m.isSilent) {
          newHistory.push(m);
        } else {
          console.log(m);
        }
      });

      setChatHistory(newHistory);
      // count of new messages compared to cached time
      const readTime = localStorage.getItem("chat-read-time");
      const newMessages = newHistory.filter((m) => Number(m.timestamp) > Number(readTime) && m.userId !== uuid).length;
      setNewMessagesWhileClosed(newMessages);
      // setNewMessagesWhileClosed(newHistory.length);
    }
  }, [getChatHistory.data, getChatHistory.isFetched]);

  // When  a chat was recieved, add it to the history 
  // not the best approach but it works for now
  useEffect(() => {
    if (!chatRx) return;
    chatRx.userId = chatRx.username;
    chatRx.color = data[chatRx.userId]?.color || "#ababab";
    chatRx.username = data[chatRx.userId]?.username || "Unknown user";
    chatRx.fontIndex = data[chatRx.userId]?.fontIndex || 0;
    chatRx.clueAttachments = [];
    // @ts-ignore
    chatRx.isSilent = (chatRx.isSilent === "true");
    chatRx.verified = data[chatRx.userId]?.verified || false;

    const clueAttachments = findClueAddresses(decodeURI(chatRx.message));
    clueAttachments.forEach((address) => {
      const found = findClueByAddress(address.clueLabel, address.direction);
      if (found) {
        chatRx.clueAttachments?.push({
          clueLabel: address.clueLabel,
          direction: address.direction,
          clueIndex: found.index,
          text: found.clue.text[0] || "",
          clue: found.clue,
        });
      }
    });

    const updatedHistory = [...chatHistory];
    updatedHistory.filter((e) => e.userId === chatRx.userId).forEach((e) => {
      // upate the username and color of the history if it has chagned
      e.username = chatRx.username;
      e.fontIndex = chatRx.fontIndex;
      e.color = chatRx.color;
      e.verified = chatRx.verified;
    });
    if (!chatRx.isSilent) {
      setChatHistory([
          ...updatedHistory, 
          chatRx
      ]);
      !chatRoomOpen && setNewMessagesWhileClosed(newMessagesWhileClosed + 1);
    } else {
      console.log(chatRx);
    }
  }, [chatRx]);

  useEffect(() => {
    newMessagesWhileClosed > 0 && setNewMessagesWhileClosed(0);

    const newHistory: Chat[] = [];
    chatHistory.forEach((m) => {
      if (!m.userId) return;
      m.color = data[m.userId]?.color || "#ababab";
      m.username = data[m.userId]?.username || "Unknown user";
      m.fontIndex = data[m.userId]?.fontIndex || 0;
      m.clueAttachments = [];
      m.verified = data[m.userId]?.verified || false;

      const clueAttachments = findClueAddresses(decodeURI(m.message));
      clueAttachments.forEach((address) => {
          const found = findClueByAddress(address.clueLabel, address.direction);
          if (found) {
            m.clueAttachments?.push({
              clueLabel: address.clueLabel,
              direction: address.direction,
              clueIndex: found.index,
              text: found.clue.text[0] || "",
              clue: found.clue,
            });
          }
        });
      newHistory.push(m);
    });
    setChatHistory(newHistory);

    // Cache the last time the messages were read
    chatRoomOpen && localStorage.setItem("chat-read-time", Date.now().toString());
  }, [chatRoomOpen]);

  // When the date changes, clear local state
  useEffect(() => {
    // setRx({});
    // setInitialDataReceived(undefined);
    // setTimeout(() => {
    //   socket?.send("_resync_");
    // }, 1000);
    clearLocalState();
  }, [date]);

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    if (iOS) {
      // The user switched apps or windows
      document.addEventListener("blur", onVisibilityChange);
    }
  
    return () => document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);

  useEffect(() => {
    if(socket && socket.readyState === 1) {
      clearLocalState();
      socket.send("_resync_");
    }
  }, [socket, socket?.readyState, visibility]);

  useEffect(() => {
    if (!gameData?.data?.data?.body || initialDataReceived === undefined) return;
    else if (initialDataReceived && !initialDataReceived[uuid] && gameData?.data?.data?.body?.[0]?.clues[0]?.cells) {
      setCells(gameData?.data?.data?.body?.[0]?.clues[0]?.cells);
      // sendCells(gameData?.data?.data?.body?.[0]?.clues[0]?.cells || [0]);
    } else if(initialDataReceived[uuid]) {
        setCellPointer(initialDataReceived[uuid].selected);
        setCells(initialDataReceived[uuid].cells);
        setClueDirection(initialDataReceived[uuid]?.direction || "across");
        setAnswers(initialDataReceived[uuid].values);
        findClueWithCell(initialDataReceived[uuid].selected, initialDataReceived[uuid].selected);
     }
  }, [initialDataReceived, gameData.data]);

  
  useEffect(() => {
    //if (rx[uuid] && rx[uuid].updated < updated) {
    // if (rx[uuid] && rx[uuid].updated < updated) {
      // delete rx[uuid];
    // }
    const newData = {...data };
    Object.keys(rx).forEach((user: any) => {
      if (user !== uuid) {
        newData[user] = rx[user];
      } else if (rx[user].updated > updated) {
        newData[user] = rx[user];
      }
    });
    // const newData = {...data, ...rx};

    if (Object.keys(rx).length === 0) {
      clearLocalState();
    } else {
      setData(newData);
    }


    if (!initialDataReceived && Object.keys(rx).length > 0) {
      setInitialDataReceived(rx);
    }

  }, [rx]);

  const onVisibilityChange = async() => {
    const isLocalhost = window.location.hostname === "localhost";
    // if (isLocalhost) return;
    if ((document.hidden || !document.hasFocus())) {
      // window.location.href = "/disconnected/" + game + "?version=" + VERSION;
      navigate("/disconnected/" + game + "?version=" + VERSION);
    }
    setVisibility(!document.hidden);

    if ("wakeLock" in navigator && settings.settings.keepScreenAwake) {
      if (wakeLock !== null && document.visibilityState === "visible") {
        //@ts-ignore
        wakeLock = await navigator.wakeLock.request("screen");
      } else {
        //@ts-ignore
        wakeLock = await navigator.wakeLock.release("screen");
      }
    }
  }

  const clearLocalState = () => { 
    setInitialDataReceived(undefined);
    setCells([0]);
    setClueIndex(0);
    setClueDirection("across");
    setCellPointer(0);
    setAnswers([]);
    setUpdated(0);
    setData({});
  }

  const findClueByAddress = (label: string, direction: string): any  => {
    if (!gameData?.data?.data?.body) return;
    const clues = gameData?.data?.data?.body[0].clues;
    let found = undefined;
    clues.forEach((c: any, i: Number) => {
      if (c.direction.toLowerCase().charAt(0) === direction.toLowerCase().charAt(0) &&
          c.label === label
      ){
        found =  {
          clue: c,
          index: i,
        }
      }
    });
    return found;
  }

  const renderKeyboard = () => {
    const buttons = KEYBOARD_LAYOUTS[settings.settings.keyboardLayout || 0];

    let buttonsAsString = "";
    buttons.forEach((b) => {
      buttonsAsString += b + " ";
    });

    const pencilStyles = [{
      class: pencilOverride ? "hg-p-select" : "hg-pencil",
      buttons: "✏️",
    }, {
      class: pencilOverride ? "hg-pen" : "hg-p-select",
      buttons: "🖊️",
    }, {
      class: rebusModalOpen ? "hg-p-select" : "hg-rebus",
      buttons: "REBUS",
    }, {
      class: "hg-p-delete",
      buttons: "◄",
    }];

    return iOS && 
      <div className={"keyboard"}>
          <KeyboardReact 
            onKeyReleased={(button, e) => {
              if (button === "◄") {
                handleKey(" ", false);
              } else if (button === "Flip") {
                findClueWithCell(cellPointer, cellPointer);
              } else if (button === "✏️") {
                if (pencilOverride) {
                  convertAnswersTo("pencil");
                }
                setPencilOverride(true);
              } else if (button === "🖊️") {
                // if it's already false, convert answers to pen
                if(pencilOverride === false) {
                  convertAnswersTo("pen");
                }
                setPencilOverride(false);
              } else if (button === "REBUS" || button === "REB") {
                setRebusModalOpen(true);
              } else if (button === "📌") {
                // const message = prompt("Enter comment");
                // if (message) {
                  const newAnswers = getAllAnswers();
                  if (newAnswers[cellPointer]?.comment?.type === "pin") {
                    newAnswers[cellPointer].comment = undefined;
                  } else {
                    newAnswers[cellPointer] = {
                      letter: newAnswers[cellPointer]?.letter || " ",
                      isPencil: newAnswers[cellPointer]?.isPencil || false,
                      color: newAnswers[cellPointer]?.color || "#000000",
                      timestamp: Date.now().toString(),
                      comment: {
                        author: uuid,
                        text: `${uuid} pinned this cell`,
                        timestamp: Date.now().toString(),
                        color: settings.settings.color,
                        type: "pin"
                      }
                    }
                  }

                  setAnswers(newAnswers);
                // }
              } else {
                handleKey(button, false)
              }
            }}
            display={{
              '◄': '⌫',
            }}
            layout={{
              default: buttons,
            }}
            theme={isDarkMode ? "hg-theme-default darkKeyboard" : ""}
            buttonTheme={isDarkMode ? [
              {
                class: "hg-dark",
                buttons: buttonsAsString,
              },
              ...pencilStyles
            ] : [...pencilStyles]}
          />
      </div>
  }

  useEffect(() => {
    // Kind of dumb, but don't send the cells unless something has changed
    if (answers.length > 0 || cells.length > 1) {
      sendCells(cells);
    }
  }, [cells, answers, clueDirection, cellPointer, clueIndex]);

  /**
   * To maintain an up to date pointer, we need to listen for key presses
   * and update the pointer accordingly.  The listener gets lost in the 
   * react state, so we need to add it to the window and remove it when
   * the component unmounts.
   */
  useEffect(() => {
    window.removeEventListener("keydown", handleKeyPress);
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    }
  }, [cells, clueIndex, answers, chatActive, cellPointer, rebusModalOpen, settings.open]);


  /** 
   * If the changes the setting to keep the screen awake, handle that
   */

  useEffect(() => {
    lockScreen(settings.settings.keepScreenAwake || false);
  }, [settings.settings.keepScreenAwake]);

  const lockScreen = async (lock: boolean) => {
    if ('wakeLock' in navigator && lock) {
      if (wakeLock !== null) {
        //@ts-ignore
        wakeLock = await navigator.wakeLock.request("screen");
      }
    } else {
      if (wakeLock !== null) {
        //@ts-ignore
        wakeLock = await navigator.wakeLock.release("screen");
      }
    }
  }


  useEffect(() => {
    // win check
    checkAnswers(undefined, false, true);
    addCrossOrigin();
  }, [data]);

  const convertAnswersTo = (type: "pencil" | "pen") => {
    // get currently selected clue cells
    if (!gameData.data) return;
    const currentAnswers = getAllAnswers();
    const cellsToConvert = gameData.data.data.body[0].clues[clueIndex].cells;
    const newAnswers = [...answers];
    cellsToConvert.forEach((cell: number) => {
      newAnswers[cell] = {
        letter: currentAnswers[cell]?.letter || " ",
        isPencil: type === "pencil",
        timestamp: Date.now().toString(),
        color: currentAnswers[cell]?.color || "#000000",
        comment: currentAnswers[cell]?.comment,
      }
    });
    setAnswers(newAnswers);
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (chatActive || rebusModalOpen || settings.open) return;
    e.preventDefault();
    e.stopPropagation();
    // check if tab key
    if (e.key === "Tab") {
      if (e.shiftKey) {
        findPreviousClue();
      } else {
        findNextClue();
      }
    } else if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
      setCellPointer(cells[(cells.indexOf(cellPointer) || 1) - 1]);
    } else if (e.key === "ArrowRight" || e.key === "ArrowDown") {
      setCellPointer(cells[(cells.indexOf(cellPointer)) + 1] || cells[0]);
    } else if (e.key === "`") {
      setRebusModalOpen(true);
    } else if (e.key === "Backspace") {
      handleKey(" ", false);
    } else if (e.key === "Enter") {
      findNextClue();
    } else if (e.key === " ") {
      findClueWithCell(cellPointer, cellPointer);
    } else {
      handleKey(e.key.toUpperCase(), e.shiftKey);
    }
  };


  const handleKey = (key: string, isPencil: boolean, isRebus: boolean = false) => {
    if (key.length > 1 && !isRebus) return;
    const newAnswers = [...answers];
    const allAnswers = getAllAnswers();
    const isDelete = key === " ";
    const prev = allAnswers[cellPointer];
    newAnswers[cellPointer] = {
      letter: key,
      timestamp: Date.now().toString(),
      isPencil: isPencil || pencilOverride,
      color: "#000000",
      comment: allAnswers[cellPointer]?.comment,
    }
    
    setAnswers(newAnswers);
    let  offset = isDelete ? -1 : 1;
    
    // if deleting a character in a cell, stay in the current cell unless it's empty
    if (isDelete && prev?.letter && prev?.letter !== " " && settings.settings.holdCursorOnCellWhenDelete) {
      offset = 0;
    }


    let newPtr = cells[(cells.indexOf(cellPointer) || 0) + offset];
    

    // Save the point temporarily so that if the whole word is traversed and no new pointer is found
    // the tmp point can be used
    const tmpPtr = cells[(cells.indexOf(cellPointer) || 0) + offset];
    // IF THIS CAUSES PROBLEMS COMMENT THIS OUT 
    // Causes the pointer to not allow editing of same word
    while (!isDelete && newPtr && allAnswers[newPtr]?.letter && allAnswers[newPtr]?.letter !== " ") {
      newPtr = cells[(cells.indexOf(newPtr) || 0) + offset];
    }
    if (!isDelete && !newPtr) {
      newPtr = tmpPtr;
    }
    
    if (newPtr === undefined) {
      // find next clue
      // isDelete ? findPreviousClue() : findNextClue();
      !isDelete && findNextClue();
    } else {
      setCellPointer(newPtr);
    }
  }

  const findNextClue = (increment = 1) => {
    let idx = clueIndex + increment;
    if (idx >= gameData?.data?.data?.body?.[0]?.clues.length) {
      idx = 0;
      // check if won 
      // checkAnswers(undefined, false, true);
      setActiveClue(idx, gameData?.data?.data?.body?.[0]?.clues[idx]);
      return;
    }
    const clue = gameData?.data?.data?.body?.[0]?.clues[idx];
    if (clue) {
      if ((!isClueFullySolved(clue) || idx === 0) && clue.cells.indexOf(cellPointer) === -1) {
        setActiveClue(idx, clue);
      } else {
        findNextClue(increment + 1);
      }
    }
  }


  const isClueFullySolved = (clue: any) => {
    const cells = clue.cells;
    let solved = true;
    const allAnswers = getAllAnswers();
    cells.forEach((c: number) => {
      if (!allAnswers[c] || allAnswers[c].letter === " ") {
        solved = false;
      }
    });
    return solved;
  }

  const findPreviousClue = (decrement = 1) => {
    let idx = clueIndex - decrement;
    if (idx < 0) {
      idx = gameData?.data?.data?.body?.[0]?.clues.length - 1;
    }
    const clue = gameData?.data?.data?.body?.[0]?.clues[idx];
    if (clue) {
      if (!isClueFullySolved(clue) || idx === 0) {
        setActiveClue(idx, clue);
      } else {
        findPreviousClue(decrement + 1);
      }
    }
  }

  const sendCells = (cells: number[]) => {
    const updated = Date.now();
    setUpdated(updated);
    const d = {
      ...data,
      [uuid]: {
        "username": settings.settings.username,
        "fontIndex": settings.settings.fontIndex || 0,
        "updated": updated,
        "cells": cells,
        "color": settings.settings.color,
        "selected": cellPointer,
        "direction": clueDirection,
        "values": answers,
        "room": `${game}-${date}`,
        "verified": localStorage.getItem("verified") === settings.settings.username || false,
      }
    };
    if (socket && 
      socket.readyState === 1 && 
      JSON.stringify(d) !== JSON.stringify(rx) && 
      JSON.stringify(d) !== JSON.stringify(data) && 
      JSON.stringify(d) !== JSON.stringify(initialDataReceived)) {
      setData(JSON.parse(JSON.stringify(d)));
      socket.send(JSON.stringify(d));
    }
  }

  const findClueWithCell = (cell: number, activeCell?: number) => {
    gameData?.data?.data.body[0].clues.forEach((clue: any, index: number) => {
      if (clue.cells.includes(cell)) {
        // if (cells.includes(cell) && clueDirection !== clue.direction.toLowerCase()) {
        if (cellPointer === cell && clueDirection !== clue.direction.toLowerCase()) {
          setActiveClue(index, clue, activeCell);
        } else if (cellPointer !== cell && clueDirection === clue.direction.toLowerCase()) {
          setActiveClue(index, clue, activeCell);
        } 
      }
    });
  }

  const setActiveClue = (index: number, clue: any, activeCell?: number) => {
    setClueIndex(index);
    setCells(clue.cells);
    setCellPointer(activeCell || findNextEmptyCell(clue));
    setClueDirection(clue.direction.toLowerCase());
  }

  const findNextEmptyCell = (clue: any): number => {
    let idx = clue.cells[0];
    const joinedAnswers = getAllAnswers();
    for (let i = 0; i < clue.cells.length; i++) {
      if (
          !joinedAnswers[clue.cells[i]]?.letter || 
          joinedAnswers[clue.cells[i]].letter === " ") {
        idx = clue.cells[i];
        break;
      }
    }
    return idx;
  }


  const getAllAnswers = () => {
    const allAnswers: Answer[] = [...answers];
    Object.keys(data).forEach((user: any) => {
      const ta = data[user].values;
      ta.forEach((answer: Answer, index: number) => {
        if (answer && answer.letter) {
          // If there is no answer OR there is an answer, but the timestamp is older
          // update the answer 
          if (!allAnswers[index] || allAnswers[index].timestamp < answer.timestamp) {
            allAnswers[index] = answer;
          }
        }
      });
    });
    return allAnswers;
  }


  const checkAnswers = (cellsToCheck?: Answer[], reveal?: boolean, winCheck?: boolean) => {
    const answerKey = gameData?.data?.data?.body?.[0]?.cells;
    if (!answerKey) return;
    let isAll = false;
    if (cellsToCheck === undefined || cellsToCheck === null) {
      cellsToCheck = getAllAnswers();
      isAll = true;
    }
    const userAnswers: Answer[] = [...getAllAnswers()];
    const checkedAnswers: Answer[] = [...answers];
    let wrongCount = 0;

    answerKey.forEach((cell: any, index: number) => {
       if((cellsToCheck?.[index] || isAll) && cellsToCheck?.[index]?.letter?.toUpperCase() !== cell?.answer?.toUpperCase()) {
        if (reveal) {
          userAnswers[index] = {
            letter: cell?.answer?.toUpperCase() || " ",
            isPencil: false,
            timestamp: Date.now().toString(),
            color: "#000000",
            comment: cellsToCheck?.[index]?.comment,
          }
        } else {
          checkedAnswers[index] = {
            letter: cellsToCheck?.[index]?.letter?.toUpperCase() || " ",
            isPencil: false,
            timestamp: Date.now().toString(),
            color: "#FF0000",
            comment: cellsToCheck?.[index]?.comment,
          }
          wrongCount += 1;
        }
       }
    });

    if (winCheck && wrongCount === 0) {
      setHasWon(true)
    } else if (reveal || (!winCheck && wrongCount > 0)) {
      setAnswers(reveal ? userAnswers : checkedAnswers);
    }
  };

  const clearPuzzle = () => {
    const answerKey = gameData?.data?.data?.body[0].cells;
    if (!answerKey) return;
    const clearedAnssers: Answer[] = [];
    answerKey.forEach((cell: any, index: number) => {
      clearedAnssers[index] = {
        letter: " ",
        isPencil: false,
        timestamp: Date.now().toString(),
        color: "#000000",
      }
    });
    setAnswers(clearedAnssers);
  }

  const getAnswersForCells = (cells: number[]) => {
    const answers: Answer[] = [];
    const allAnswers = getAllAnswers();
    Object.keys(data).forEach((user: any) => {
      allAnswers.forEach((answer: Answer, index: number) => {
        if (cells.includes(index)) {
          answers[index] = answer;
        }
      });
    });

    // If no one has entered an answer to the cell, create a blank answer
    cells.forEach((cell: number) => {
      if (!answers[cell]) {
        answers[cell] = {
          letter: " ",
          isPencil: false,
          timestamp: Date.now().toString(),
          color: "#000000",
          comment: allAnswers[cell]?.comment,
        }
      }
    });
    return answers;
  }

  const renderRebusModal = () => {
    return (
      <Dialog open={rebusModalOpen} >
        <DialogContent>
          <DialogContentText
            variant="body2"
            style={{marginBottom: "20px"}}
          >
            Enter the text you want to use for the rebus.  You can also use special characters and emojis here.
          </DialogContentText>
          <TextField
            autoFocus
            label="Rebus"
            type="text"
            fullWidth
            variant="standard"
            value={rebusInput}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                setRebusModalOpen(false);
                handleKey(rebusInput, pencilOverride, true);
              }
            }}
            onChange={(e) => setRebusInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRebusModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            setRebusModalOpen(false);
            handleKey(rebusInput, pencilOverride, true);
          }} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const render = () => {
    if (requireInstall()) return <InstallInfo roomName={game}/>;
    if (gameData.isLoading) return <Loader />;
    if (gameData.isError || !gameData.data?.data?.body) return <Error />;
    return (
      <div className="game-container">
        <div id="game">
          {chatRoomOpen && <ChatRoom
            chatHistory={chatHistory}
            currentUser={uuid}
            currentClue={gameData.data.data.body[0].clues[clueIndex]}
            onActiveChat={(active) => setChatActive(active)}
            isMobile={iOS}
            answers={getAllAnswers()}
            onRefetch={() => {
              getChatHistory.refetch();
            }}
            onAttachmentClicked={(clueId, direction) => {
              setActiveClue(clueId, gameData.data.data.body[0].clues[clueId]);
              if (iOS) {
                setChatRoomOpen(false);
              }
            }}
            onMessageSent={(message) => {
              if (socket && socket.readyState === 1) {
                // make message special characters url safe
                message = encodeURI(message);
                socket.send("_chat_"+message);
              }
            }}
          />
          }
          {/* <Button onClick={() => checkAnswers(getAnswersForCells(cells))}>Check</Button> */}
          {/* <Button onClick={() => checkAnswers(undefined, true)}>Reveal</Button> */}
          {/* <Button onClick={() => checkAnswers(getAnswersForCells(cells), true)}>Reveal Word</Button> */}
          <Board 
            board={gameData.data.data.body[0].board} 
            data={data} 
            users={onlineUsers}
            currentUser={uuid}
            overlays={findOverlay(gameData.data.data, hasWon)}
            selectedCell={cellPointer}
            onCellClicked={(cell) => {
              findClueWithCell(cell, cell);
            }}
          />
          {
            iOS && listView &&
            <ClueBoard
              clues={gameData.data?.data?.body?.[0]?.clues}
              clueSet={clueIndex}
              onClueSelect={(clue: Clue, index: number) => {
                setActiveClue(index, clue);
              }}
              answers={getAllAnswers()}
              selectedCell={cellPointer}
              selectedColor={`${settings.settings.color}50`}
              onCellSelect={(cell: number) => {
                setCellPointer(cell);
              }}
              dots={
                Object.keys(data).filter((user) => user !== uuid).map((user: any) => {
                  return {
                    color: data[user].color,
                    cellIndex: data[user].selected,
                    direction: data[user].direction,
                  }
                })
              }
            />
          }
          {!iOS &&
            <Clues 
              clues={gameData.data.data.body[0].clues}
              clueSet={clueIndex}
              onClueSelect={(cells, index) => {
                setActiveClue(index, gameData.data.data.body[0].clues[index]);
              }}
            />
          }
          <div>
            {iOS && 
              <ClueScroller
                clues={gameData.data.data.body[0].clues}
                clueSet={clueIndex}
                onNext={() => findNextClue()}
                onPrevious={() => findPreviousClue()}
                onTap={() => findClueWithCell(cellPointer, cellPointer)}
                onClueSelect={(cells, index) => {
                  setActiveClue(index, gameData.data.data.body[0].clues[index]);
                }}

              />}
            {renderKeyboard()}
          </div>
            
        </div>
        {renderRebusModal()}
      </div>
    );
  }
  return (
    <div className="game-root">
      <SocialBar 
        users={Object.keys(data).map((d: any) => {return {
          fontIndex: settings.settings.disableUserFonts ? 0 : data[d].fontIndex,
          id: d,username: data[d].username || d, color: data[d].color, 
          verified: data[d].verified,
          isOnline: onlineUsers.indexOf(d) > -1}})}
        currentUser={uuid}
        onChatButtonClicked={() => {
          setChatRoomOpen(!chatRoomOpen);
          localStorage.setItem("chat-read-time", Date.now().toString());
        }}
        unreadMessageCount={newMessagesWhileClosed}
        cellComment={getAllAnswers()[cellPointer]?.comment}
        menuOptions={[
          {
            label: "Settings",
            // onClick: () => setSettingsModalOpen(true),
            onClick: () => settings.showSettingsWindow(),
            icon: <LuSettings2 />
          },
          {
            label: "Rebus",
            onClick: () => {setRebusModalOpen(true)},
            icon: <VscWholeWord />,
          },
          {
            label: "",
            onClick: () => {}
          },
          {
            label: "Check Word",
            onClick: () => checkAnswers(getAnswersForCells(cells), false),
            icon: <BsUiChecksGrid />,
            // disabled: true,
          },
          {
            label: `Reveal Word ${new Date().getHours() < 18 ? "(6pm)" : ""}`,
            onClick: () => {
              alert({
                message: "Are you sure you want to reveal this word?",
                onConfirmCallback: () => {checkAnswers(getAnswersForCells(cells), true)},
                onCancelCallback: () => {},
              });
            },
            disabled: new Date().getHours() < 18,
            icon: <CgSearchLoading />
          },
          {
            label: "",
            onClick: () => {}
          },
          {
            label: `Check Puzzle ${new Date().getHours() < 13 ? "(1pm)" : ""}`,
            onClick: () => {
              alert({
                message: "Are you sure you want to check the entire puzzle?  This will check for everyone.",
                onConfirmCallback: () => {checkAnswers(undefined, false, false)},
                onCancelCallback: () => {},
              });
            },
            disabled: new Date().getHours() < 13,
            icon: <FaFlagCheckered />,
          },
          {
            label: `Reveal Puzzle ${new Date().getHours() < 18 ? "(6pm)" : ""}`,
            onClick: () => {
              alert({
                message: "Are you sure you want to reveal the entire puzzle?  This will reveal for everyone.",
                onConfirmCallback: () => {checkAnswers(undefined, true)},
                onCancelCallback: () => {},
              });
            },
            disabled: new Date().getHours() < 18,
            icon: <CgSearch />
          },
          {
            label: "",
            onClick: () => {}
          },
          {
            label: "Clear Cache",
            onClick: () => {
              alert({
                message: "Are you sure you want to clear your cache?  This will also reset your name and color.",
                onConfirmCallback: () => {
                  localStorage.clear();
                  window.location.reload();
                },
                onCancelCallback: () => {},
              });
            },
            icon: <ImInfo />
          },
          {
            label: "Resync",
            onClick: () => {
              if (socket && socket.readyState === 1) {
                clearLocalState();
                socket.send("_resync_");
              }
            },
            icon: <ImInfo />
          },
          {
            label: "Clear Puzzle",
            onClick: () => {
              alert({
                message: "Are you sure you want to clear the puzzle?",
                onConfirmCallback: () => {clearPuzzle()},
                onCancelCallback: () => {},
              });
            },
            disabled: false,
            icon: <ImInfo />
          },

          gameData?.data?.data?.title && {
            label: "",
            onClick: () => {}
          },
          gameData?.data?.data?.title && {
            label: gameData?.data?.data?.title || "",
            onClick: () => {},
            icon: <ImInfo />
          }
        ]}
      />
      {render()}
      <Snackbar open={socket?.readyState !== 1 || !window.navigator.onLine} message={"Connection lost.  Attempting to reconnect..."} />
      {hasWon && <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        run={hasWon}
        recycle={false}
        numberOfPieces={1000}
        gravity={0.1}
        onConfettiComplete={(confetti) => {
          setTimeout(() => {
            setHasWon(false);
          }, 10000);
          confetti?.reset();
        }}
        colors={Object.keys(data).map((d: any) => data[d].color)}
      />}
    </div>
  );
}

export default Game;
