import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react"
import { SOCKET_URL } from "../Constants";
import { queueFunction } from "../Util";


export const useLive = (
        room: string, 
        user: string, 
        callback: (value: any) => void, 
        onConnected: (socket: WebSocket) => void,
        onUserStatusChange: (users: string[]) => void,
        onChatMessage: (data: any) => void
    ) => {
    const [socketCounter, setSocketCounter] = useState(0);
    const queryClient = useQueryClient();
    room = room.toLowerCase().replace(/ /g, "_");
    useEffect(() => {
        const websocket = new WebSocket(`${SOCKET_URL}/${room}/${user}`);
        websocket.onopen = (e) => {
            onConnected(websocket);
        }

        websocket.onmessage = (event) => {
            if (websocket.readyState !== WebSocket.OPEN) return;
            
            const data = JSON.parse(event.data);
            if (data.type === "user_status") {
                onUserStatusChange(data.users);
            } else if (data.type === "chat" || data.type === "system") {
                queueFunction(() => onChatMessage(data), 10);
            } else if(hasRoom(room, data)){
                queueFunction(() => callback(data), 10);
                // console.log(socketCounter, data);
            }
        }

        websocket.onclose = (event) => {
            setSocketCounter(socketCounter + 1);
        }

        websocket.onerror = (event) => {
            setSocketCounter(socketCounter + 1);
        }

        return () => {
            websocket.close();
        }
    }, [queryClient, socketCounter, room, window.navigator.onLine]);

    const hasRoom = (room: string, data: any) => {
        let hr = false;
        Object.keys(data).forEach((key) => {
            if(data[key].room === room) {
                hr = true;
            }
        });
        return hr;
    }
}