import { AppBar, Toolbar, Typography } from '@mui/material';
import { isMobile } from '../Util';
//@ts-ignore
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Logo from '../components/Logo';
import { MdOutlineIosShare } from 'react-icons/md';
import {FaRegListAlt} from 'react-icons/fa';
import { useLatestVersion } from '../hooks/useLatestVersion';
import { useEffect } from 'react';
import { VERSION } from '../Version';
import { useAlerts } from '../providers/AlertProvider';


interface Props {
    onSetDark: () => void;
    currentDate: Date;
    onSetDate: (date: Date) => void;
    mobileListView: boolean;
    setMobileListView: (value: boolean) => void;
    currentPath: string;
}


const Header = ({onSetDark, currentDate, onSetDate, mobileListView, setMobileListView, currentPath }: Props) => {
    const inGame = currentPath !== "/" && currentPath !== "/disconnected";
    const gameName = currentPath.split("/")[1];
    const { alert } = useAlerts();

    const latestVersion = useLatestVersion();

    useEffect(() => {
      if (!latestVersion.isLoading && !latestVersion.isFetching) {
        if (latestVersion.data?.data?.version && latestVersion.data?.data?.version !== VERSION && latestVersion.data?.data?.version !== "0.0.0") {
          alert({
            message: `A new version of Crosswords is available.  You are on ${VERSION} and the latest version is ${latestVersion.data?.data?.version}.  Click or tap confirm to update now.`,
            onConfirmCallback: () => {
              window.location.reload();
            },
            onCancelCallback: () => {}
          });
        }
      }
      
    }, [latestVersion.isLoading, latestVersion.isFetching]);
  
    return (
        <AppBar 
        position="static"
        elevation={1}
        >
        <Toolbar
          style={{
            paddingTop: "20px",
            marginBottom: "-10px"
          }}
        >
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <div
              className={"logo-container"}
            >
              <Logo width={30} height={30} fill={"#ffffff"} 
                onClick={() => {
                  if (window.location.href === window.location.origin + "/") {
                    onSetDark();
                  } else {
                    window.location.href = "/";
                  }
                }}
              />

            {
              inGame &&
                <DatePicker
                  selected={currentDate}
                  onChange={(date: Date) => onSetDate(date)}
                  className='date-picker'
                  // customInput={<BsCalendarEvent />}
                />
            }
            </div>
          </Typography>
          {inGame &&
            <div className={"extra-buttons"}>
              {navigator.canShare && navigator.canShare({
                title: "Crossword",
                text: "Join me in "+gameName,
                url: window.location.href,
              }) && <MdOutlineIosShare 
                className="share-button"
                onClick={() => {
                  navigator.share({
                    title: "Crossword",
                    text: "Join me in "+gameName,
                    url: window.location.href,
                  })
                }}
              />}
              {
                isMobile() && <FaRegListAlt
                                onClick={() => setMobileListView(!mobileListView)}
                                style={mobileListView ? {color: "#182621"} : {}}
                              />
              }
            </div>
          }
          {/* <IconButton
            color="inherit"
            onClick={() => setDark(!dark)}
          >
            {dark ? <Icon>dark_mode</Icon> : <Icon>light_mode</Icon>}
          </IconButton> */}
        </Toolbar>
      </AppBar>
    );
}

export default Header;