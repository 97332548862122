import { useCallback, useEffect, useRef, useState } from "react";
import "./Board.css";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { getSquarePathDimensions, isMobile } from "../Util";
import { useTheme } from "@mui/material";
import { AnswerComment } from "../pages/Game";
import { useSettings } from "../providers/SettingsProvider";
import { FONTS } from "../Constants";

interface Props {
  board: string,
  data: any,
  users: string[], // array of online users
  onCellClicked: (cell: number) => void,
  currentUser: string,
  overlays?: string[],
  selectedCell: number,
}

type Listener = {
  id: string,
  listener: any,
}
const listeners: Listener[] = [];
const Board = ({board, data, users, onCellClicked, currentUser, overlays = [], selectedCell}: Props) =>{
    const [selected, setSelected] = useState<string[]>([]);
    const settings = useSettings();
    const ref = useRef<HTMLDivElement>(null);
    const zoomerRef = useRef<any>(null);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [localScale, setLocalScale] = useState(1);
    const [transX, setTransX] = useState(0);
    const [transY, setTransY] = useState(0);

    // @ts-ignore
    const onUpdate = useCallback(({ x, y, scale }) => {
      const { current: div} = ref;
      if (div && isMobile()) {
        const value = make3dTransformValue({ x, y, scale });

        div.style.setProperty("transform", value);
        // round scale to nearest 10th
        setLocalScale(scale);
        // setTransX(x);
        // setTransY(y);
      }
    }, []);

    const centerToCell = (cell: number) => {
      const cellElement = document.getElementById("cell-" + cell);
      if (!cellElement || !ref.current) return;
    
      const cellLocation = cellElement.getBoundingClientRect();
      const boardLocation = ref.current.getBoundingClientRect();
      if (localScale <= 1) return;
    
      const x = cellLocation.left - boardLocation.left + cellLocation.width / 2;
      const y = cellLocation.top - boardLocation.top + cellLocation.height / 2;
      
      const isTodayASunday = new Date().getDay() === 0;
    
      zoomerRef.current?.alignCenter({
        x,
        y,
        scale: isTodayASunday ? 2.5 : 2,
        animated: true,
      });
    };

    useEffect(() => {
      if (selectedCell && settings.settings.followCursorOnZoom) {
        centerToCell(selectedCell);
      }
    }, [selectedCell]);
    
    useEffect(() => {
      if (data) {
        const s: string[] = [];
        Object.keys(data).forEach((key) => {
          data[key].cells.forEach((i: number) => s.push(i.toString()));
        });
        setSelected(s);
      }
    }, [data]);

    useEffect(() => {
      if (overlays) {
        addOverlays(overlays);
      }
    }, [overlays, ref]);

    useEffect(() => {
      if (!ref.current) return;
      ref.current.firstChild?.childNodes.forEach((node: any) => {
        if (node.classList.contains("cells")) {
          node.childNodes.forEach((tag: any) => {
              const id = tag.getAttribute("data-index");
              if (!tag.getAttribute("id")) {
                tag.setAttribute("id", "cell-" + id);
                tag.classList.add("cwcell");
                tag.setAttribute("style", "pointer-events: fill");
              }

              // This is super dumb but there is no easy way to remove event listeners
              // so we have to keep track of them and remove them manually
              let listener = listeners.find((l) => l.id === id);
              if (listener) {
                tag.removeEventListener("click", listener.listener);
                listeners.splice(listeners.indexOf(listener), 1);
              }
              const newListener =  (e: Event) => {
                onCellClicked(parseInt(id));
              }
              tag.addEventListener("click", newListener);
              listeners.push({
                id: id,
                listener: newListener,
              });

          });
        }
      });
    }, [data, ref, onCellClicked]);



    useEffect(() => {
        for(const key in data) {
          Array.from(document.getElementsByClassName("cwcell")).forEach((cell: any) => {
            cell.classList.remove(`${key}-selected`);
            cell.classList.remove(`${key}-current`);
          });
          data[key].cells.forEach((cell: number) => {
            highlightCell(key, cell, (data[key].selected === cell));
          });

          if (data[key].values) {
            Object.keys(data[key].values).forEach((cell: string) => {
              if(data[key].values[cell] !== null) {
                setCellValue(parseInt(cell), 
                  data[key].values[cell].letter, 
                  Number(data[key].values[cell].timestamp), 
                  data[key].values[cell].isPencil, 
                  settings.settings.identifyUserAnswers ? (data[key].values[cell].color === "#FF0000" ? "#FF0000": data[key].color) : data[key].values[cell].color,
                  data[key].values[cell].comment,
                  data[key].fontIndex,
                );
              }
            });
          }
        }
    }, [data]);

    useEffect(() => {
      addColorRectOverlays();
    }, [data, users]);

    const setCellValue = (cell: number, value: string, timestamp: number, isPencil: boolean, color: string, comment: AnswerComment, fontIndex: number) => {
      const clen = document.getElementById("cell-" + cell)?.children.length || 3;
      const n = document.getElementById("cell-" + cell)?.children[clen-1] as HTMLElement;
      if (n) {
        if (n.getAttribute("updated") && timestamp < parseInt(n.getAttribute("updated") || "")) return;
        n.innerHTML = value;
        n.setAttribute("updated", timestamp.toString());
        const scalePercent = 1/value.length;
        if (color !== "#000000" && value && value !== " ") {
          n.setAttribute("style", `fill: ${color}`);
        } else {
          n.setAttribute("style", ``);
        }

        if (fontIndex && fontIndex > 0 && !settings.settings.disableUserFonts) {
          n.setAttribute("font-family", `${FONTS[fontIndex]}`);
        } else {
          n.setAttribute("font-family", FONTS[settings.settings.fontIndex || 0]);
        }

        const fs = n.getAttribute('font-size');
        const originalFontSize = Number(n.getAttribute('orig-font-size'));
        if (fs) { 
          if (originalFontSize === 0 || isNaN(originalFontSize)) {
            n.setAttribute(`orig-font-size`, fs.toString());
            n.setAttribute('font-size', `calc(${fs}px * ${Math.min(scalePercent*2, 1)})`);
          } else {
            n.setAttribute('font-size', `calc(${originalFontSize}px * ${Math.min(scalePercent*2, 1)})`);
          }
        } 

        if (isPencil) {
          n.classList.add("pencil");
        } else {
          n.classList.remove("pencil");
        }


        // Adds a "marker" to the cell that can be either a "pin" or a "comment"
        const commentCircle = document.getElementById("comment-circle-" + cell);
        if (comment) {
          // create an SVG circle and append it to the parentElement of n
          if (!commentCircle) {

            // const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
            // the parent of the node has a path with a class "cell".  Find that and get the x and y
            const cellPath = n.parentElement?.getElementsByClassName("cell")[0];

            const newPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
            newPath.setAttribute("d", cellPath?.getAttribute("d") || "");
            newPath.setAttribute("fill", comment.color+"20");
            newPath.setAttribute("stroke", "#000000");
            newPath.setAttribute("stroke-width", "3");
            newPath.setAttribute("stroke-linecap", "round");
            newPath.setAttribute("stroke-linejoin", "round");
            newPath.setAttribute("id", "comment-circle-" + cell);
            newPath.setAttribute("message-time", comment.timestamp.toString());
            newPath.setAttribute("author", comment.author);
            newPath.setAttribute("style", "pointer-events: none");
            newPath.setAttribute("message", comment.text);

            n.parentElement?.prepend(newPath);
          } else {
            commentCircle.setAttribute("fill", comment.color+"20");
            // commentCircle.setAttribute("stroke", comment.color);
            commentCircle.setAttribute("stroke", "#000000");
            // commentCircle.setAttribute("message", comment.text);
            commentCircle.setAttribute("message-time", comment.timestamp.toString());
            commentCircle.setAttribute("author", comment.author);
          }

        } else if (commentCircle && !comment) {
          commentCircle.remove();
        }
      }
    }

    const addColorRect = (node: HTMLElement, colors: string[]) => {

      const svgNode = node?.getElementsByClassName("cell")[0];
      if (!svgNode) return;

      const dim = getSquarePathDimensions(svgNode as SVGPathElement);
      if (!dim) return;

      const svgNS = 'http://www.w3.org/2000/svg';

      // Calculate the width of each color block based on the number of colors
      const colorWidth = (100 / colors.length) * (dim.width / 100);
    
      // Create a group element to contain the colored rectangles
      // const group = document.createElementNS(svgNS, 'g');
      const group = document.createDocumentFragment();
    
      // Loop through the colors and create rectangles
      colors.forEach((color, index) => {

        const x = (index * colorWidth + dim.x).toString();
    
        // Create a rectangle element
        const rect = document.createElementNS(svgNS, 'rect');
        rect.setAttribute('x', x);
        rect.setAttribute('y', String(dim.y));
        rect.setAttribute('width', colorWidth.toString());
        rect.setAttribute('height', dim.height.toString());
        rect.setAttribute('fill', color);
        rect.classList.add("color-splitter");
        rect.setAttribute("style", "pointer-events: fill");

        // Append the rectangle to the group
        group.appendChild(rect);
      });
    
      // Append the group to the SVG
      // svgNode.parentElement?.prepend(group);
      // insert node just after svgNode
      svgNode.parentElement?.insertBefore(group, svgNode.nextSibling);     
    }

    const addColorRectOverlays = () => {
      // Remove Old
      const prevList = document.getElementsByClassName("color-splitter");
      Array.from(prevList).forEach((node) => {
        node.remove();
      });

      const arr = Array.from(document.getElementsByClassName("cwcell"));
      arr.forEach((node) => {
          if (!node) return;
          // get classes
          const classes = node.classList;
          if (!classes || classes.length === 0) return;

          // get list of uuids from classes
          const uuids = Array.from(classes).filter((c) => c.endsWith("-selected")).map((c) => c.replaceAll("-selected", "").replaceAll("-current", "")); 

          // get colors for each user by uuid
          const colors = uuids.filter((u) => data[u] !== undefined).map((uuid) => {
            let opacity = data[uuid].selected === parseInt(node.getAttribute("data-index") || "") ? "99" : "40";

            let color = data[uuid].color;

            if (users.indexOf(uuid) === -1) {
              if (settings.settings.showLocationOfOfflineUsers) {
                color = "#adadad";
              } else {
                color = "#000000";
                opacity = "00";
              }
            }

            return color + opacity;
          });

          addColorRect(node as HTMLElement, colors.filter((c) => c !== "#00000000"));
      });
    }

    const highlightCell = (user: string, cell: number, isSelected: boolean) => {
      const n = document.getElementById("cell-" + cell) as HTMLElement;
      if (!n) return;
      n.classList.add(`${user}-selected`);
      isSelected && n.classList.add(`${user}-current`);
      // const commentCircle = document.getElementById("comment-circle-" + cell);
      // if (commentCircle && isSelected && user === currentUser) {
      //   showTextBubble(commentCircle);
      //   console.log(cell);
      // } else if (!commentCircle && isSelected && user === currentUser) {
      //   // remove all bubbles
      //   const bubbles = document.getElementsByClassName("bubble");
      //   Array.from(bubbles).forEach((bubble) => {
      //     bubble.remove();
      //   });
      // }
    }

    const showTextBubble = (circle: HTMLElement) => {
      // create a speech bubble, add it to the svg, and animate it
      console.log("showing bubble");
      const svg = ref.current?.firstChild;
      if (!svg) return;
      const text = circle.getAttribute("message");
      const textTime = circle.getAttribute("message-time");
      const author = circle.getAttribute("author");
      const x = Number(circle.getAttribute("cx") || "0");
      const y = Number(circle.getAttribute("cy") || "0");
      const bubble = document.createElementNS("http://www.w3.org/2000/svg", "g");
      const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      const textNode = document.createElementNS("http://www.w3.org/2000/svg", "text");
      // add the text to the textNode
      textNode.innerHTML = text || "No comment";
      textNode.setAttribute("x", (x + 5).toString());
      textNode.setAttribute("y", (y).toString());
      textNode.setAttribute("font-size", "12");
      textNode.setAttribute("fill", "white");
      textNode.setAttribute("style", "pointer-events: none");
      // add the rect to the bubble
      // const textWidth = textNode.getBBox().width;
      // const textHeight = textNode.getBBox().height;
      // because the above doesn't work
      const textWidth = (text || "").length * 3;
      const textHeight = 10;

      rect.setAttribute("x", (x + 2).toString());
      rect.setAttribute("y", (y - 12).toString());
      rect.setAttribute("width", (textWidth + 40).toString());
      rect.setAttribute("height", (textHeight + 20).toString());
      rect.setAttribute("fill", "black");
      rect.setAttribute("style", "pointer-events: none");
      // add the bubble to the svg
      bubble.appendChild(rect);
      bubble.appendChild(textNode);
      bubble.classList.add("bubble");
      bubble.setAttribute("style", "pointer-events: none");
      svg.appendChild(bubble);

    }


    const renderStyles = () => {
      const styles = Object.keys(data).map((key) => {
        // There is handling to display offline users has a light color
        // but for now, we won't display them at all.  Maybe a config option?
        return;
        if (users.indexOf(key) === -1 && !settings.settings.showLocationOfOfflineUsers) return (<style key={"currentUser-style"}></style>);
        return (<style key={`style-key-${key}`}>
           {`
            .${key}-selected > .cell {
              fill-opacity: 0.2;
              fill: ${users.indexOf(key) > -1 ? data[key].color : "#adadad"} ${key === currentUser ? "!important" : ""};
            }
            .${key}-current > .cell {
              fill-opacity: 0.7 !important;
            }
          `}
        </style>);
      });

      return (
        <>{styles}</>
      );
    }

    const addOverlays = (pngs: string[]) => {
      if (!ref.current) return;
      clearOverlays();
      pngs.forEach((png) => {
        addOverlay(png);
      });
    }

    const clearOverlays = () => {
      if (!ref.current) return;
      const svg = ref.current.firstChild;
      if (!svg) return;
      svg.childNodes.forEach((node: any) => {
        if (node.classList.contains("overlay")) {
          svg.removeChild(node);
        }
      });
    }

    const addOverlay = (png: string) => {
      if (!ref.current) return;
      const svg = ref.current.firstChild;
      if (!svg) return;
      const img = document.createElementNS("http://www.w3.org/2000/svg", "image");
      img.setAttributeNS("http://www.w3.org/1999/xlink", "href", png);
      img.setAttribute("width", "100%");
      img.setAttribute("height", "100%");
      img.setAttribute("x", "0");
      img.setAttribute("y", "0");
      img.setAttribute("preserveAspectRatio", "none");
      // allow for click through
      img.setAttribute("style", "pointer-events: none");
      img.classList.add("overlay");
      svg.appendChild(img);
    }

    return (
      <div id="board" key="board">
        {renderStyles()}
        <QuickPinchZoom 
          draggableUnZoomed={false}
          setOffsetsOnce={false}
          onUpdate={(o) => {
            setLocalScale(o.scale);
            onUpdate(o);
          }} ref={zoomerRef} >
          <div key="board-svg" ref={ref} id="board-svg" dangerouslySetInnerHTML={{ __html: board }} style={isDarkMode ? {fill: "white", background: "#303030"}: {}}/>
        </QuickPinchZoom>
      </div>
    );
  }
  
export default Board;
  