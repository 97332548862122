import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Game, { gameLoader } from './pages/Game';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { dark_theme, light_theme } from './theme';
import { dateToString } from './Util';
import Disconnected from './pages/Disconnected';
import Error from './components/Error';
import Header from './components/Header';
import AlertProvider from './providers/AlertProvider';
import SettingsProvider from './providers/SettingsProvider';

function App() {
  const [dark, setDark] = useState<boolean>(localStorage.getItem("dark") === "true" ? true : false);
  const [date, setDate] = useState<Date>(new Date());
  const [mobileListView, setMobileListView] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />,
      errorElement: <Error />,
      shouldRevalidate: () => true,
    },
    {
      path: '/:game',
      search: '?version=:version',
      element: <Game 
        date={dateToString(date)}
        listView={mobileListView}
      />,
      // @ts-ignore
      loader: gameLoader,
      errorElement: <Error />,
      shouldRevalidate: () => true,
    }, 
    {
      path: '/disconnected/:game',
      search: '?version=:version',
      element: <Disconnected />,
      // @ts-ignore
      loader: gameLoader,
      errorElement: <Error />,
      shouldRevalidate: () => true,
    }
  ]);

  router.subscribe((e) => {
    if (currentPath === e.location.pathname) return;
    setCurrentPath(e.location.pathname);
  });

  useEffect(() => {
    localStorage.setItem("dark", dark.toString());
  }, [dark]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 60 * 24,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    }
  });



  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={dark ? dark_theme : light_theme}>
        <SettingsProvider onChange={(settings) => {
          setDark(settings.theme === "dark");
        }}>
          <AlertProvider>
            <div className="App"
              style={{
                backgroundColor: dark ? "#000000" : "#FFF7EB",
                color: dark ? "#fff" : "#000",
              }}
            >
              <Header
                onSetDark={() => setDark(!dark)}
                currentDate={date}
                onSetDate={(date: Date) => setDate(date)}
                mobileListView={mobileListView}
                setMobileListView={(value: boolean) => setMobileListView(value)}
                currentPath={currentPath}
              />

              <RouterProvider router={router} />
            </div>
          </AlertProvider>
        </SettingsProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
