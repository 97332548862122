import { useEffect } from "react";
import "./Clues.css";
import { IMO_PROXY } from "../Constants";

interface Props {
    clues: Clue[],
    onClueSelect: (cells: number[], clueIndex: number) => void,
    clueSet?: number,
}

type Clue = {
    cells: number[],
    direction: string,
    label: string,
    text: [
        {
            formatted?: string,
            plain: string,
        }
    ]
};

const Clues = ({clues, onClueSelect, clueSet} : Props) => {
    useEffect(() => {
        if (clueSet !== undefined) {
            // find and remove all elements with class "selected-clue"
            const selectedClues = document.getElementsByClassName("selected-clue");
            for (let i = 0; i < selectedClues.length; i++) {
                selectedClues[i].classList.remove("selected-clue");
            }

            // scroll to clue id
            const clue = document.getElementById(`clue-${clueSet}`);
            if (clue) {
                clue.scrollIntoView({behavior: "smooth", block: "nearest", inline: "start"});
                clue.classList.add("selected-clue");
            }
        }
    }, [clueSet]);

    const renderFormattedClue = (clue: Clue) => {
        return (
            <div dangerouslySetInnerHTML={{__html: clue.text[0].formatted?.replaceAll("https://", `${IMO_PROXY}https://`) || clue.text[0].plain}}>
            </div>
        )
    };

    const renderClues = () => {
        const across = [];
        const down = [];
        for (let index = 0; index < clues.length; index++) {
            const clue = clues[index];
            const element = (
                <div 
                    key={`clue-${index}`} 
                    id={`clue-${index}`} 
                    className={"clue"}
                    onClick={() => onClueSelect(clue.cells, index)}
                >
                    {clue.label} {clue.text[0].formatted ? renderFormattedClue(clue) : clue.text[0].plain}
                </div>
            );
            if (clue.direction.toLowerCase() === "across") {
                across.push(element);
            } else {
                down.push(element);
            }
        }
        return (
            <div>
                <div className={"clues-selected-text"}>
                    <span className={"clues-selected-text-number"}>{clues[clueSet || 0].label}{clues[clueSet || 0].direction.charAt(0)}</span> {clues[clueSet || 0].text[0].plain}
                </div>
                <div className="clues">
                    <div>
                        <div className={"clueDirLabel"}>Across</div>
                        <div className="clueList">
                            {across}
                        </div>
                    </div>
                    <div>
                        <div className={"clueDirLabel"}>Down</div>
                        <div className="clueList">
                            {down}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return renderClues();

}

export default Clues;