import { useEffect } from "react";

interface Props {
    width: number,
    height: number,
    fill?: string,
    onClick?: () => void,
}
const Logo = ({width, height, fill = "#000000", onClick} : Props) => {
    return (
        <svg width={`${width}px`} height={`${height}px`}  viewBox="0 0 184.32696 245.29787" onClick={onClick} style={onClick ? {cursor: "pointer"} : {}}>
            <rect x="79.53599" y="18.56507" width="86.2259" height="86.2259" transform="translate(79.53599 -68.66082) rotate(45)" style={{fill: "rgba(0,0,0,0)", stroke: fill, strokeMiterlimit: 10, strokeWidth: 4}}/>
             <rect x="18.56507" y="79.53599" width="86.2259" height="86.2259" transform="translate(104.79097 -7.68991) rotate(45)" style={{fill: "rgba(0,0,0,0)", stroke: fill, strokeMiterlimit: 10, strokeWidth: 4}}/>
             <rect x="79.53599" y="140.5069" width="86.2259" height="86.2259" transform="translate(165.76189 -32.94489) rotate(45)" style={{fill: "rgba(0,0,0,0)", stroke: fill, strokeMiterlimit: 10, strokeWidth: 4}}/>
        </svg>
    )
}

export default Logo;