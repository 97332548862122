
import { Badge, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import "./SocialBar.css";
import { BsFillChatTextFill } from "react-icons/bs";
import { IoMdMenu } from "react-icons/io";
import { useEffect, useState } from "react";
import { AnswerComment } from "../pages/Game";
import { FONTS } from "../Constants";
import UserBadge from "./UserBadge";

interface Props {
    users: User[],
    currentUser: string,
    menuOptions?: MenuOption[],
    onChatButtonClicked: () => void,
    unreadMessageCount?: number,
    cellComment?: AnswerComment,
}

type User = {
    username: string,
    color: string,
    isOnline: boolean,
    id: string,
    fontIndex: number,
}

type MenuOption = {
    label: string,
    onClick: () => void,
    icon?: any,
    disabled?: boolean,
}

const SocialBar = ({users, currentUser, menuOptions = [], onChatButtonClicked, unreadMessageCount, cellComment}: Props) =>{
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [cellCommentVisible, setCellCommentVisible] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => { 
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (cellComment && cellComment.type === "comment") {
            setCellCommentVisible(true);
        } else {
            setCellCommentVisible(false);
        }
    }, [cellComment]);

    return (
      <div className="social-bar" style={isDarkMode? {background: "#2f2f2f"} : {}}>
            <div
                id="chatButton"
                className={unreadMessageCount ? "bounceAnim" : ""}
                onClick={onChatButtonClicked}
            >
                <Badge badgeContent={unreadMessageCount} color="error">
                    <BsFillChatTextFill />
                </Badge>
            </div>
        <div className="social-bar-online">
            {users.sort((a: User, b: User) => {
                if (a.isOnline && !b.isOnline) return -1;
                if (!a.isOnline && b.isOnline) return 1;
                return 0;
            }).map((user: any, index: number) => {
                return (
                    <div 
                        className={user.isOnline ? "social-bar-user" : "social-bar-user-offline" } 
                        key={`social-bar-user-${index}`}
                        style={{height: "38px", fontFamily: FONTS[user.fontIndex]}}
                    >
                        <UserBadge
                            username={user.username}
                            isOnline={user.isOnline}
                            color={user.color}
                            isSelf={user.id === currentUser}
                            isVerified={user.verified}
                        />
                        {/* <div className="social-bar-user-color" style={{backgroundColor: user.isOnline ? user.color : "#888888"}}></div>
                        {user.username} {user.username === currentUser && "(you)"} {user.isOnline ? "" : "(offline)"} */}
                    </div>
            )})}
        </div>
        <div className="social-bar-speech-bubble"
            onClick={() => setCellCommentVisible(false)}
            style={
            cellComment && cellCommentVisible ? {
                top: "calc(var(--sat) + 15px)"
            } : {top: "calc(var(--sat) - 200px)", transitionDelay: "0s", transitionDuration: "0s"}}
        >
            <div className="social-bar-user-color" style={{backgroundColor: cellComment?.color || "#888888"}}></div>
            <div>
                <strong>{cellComment ? users.find((u) => u.id === cellComment.author)?.username: "Someone "}:&nbsp;</strong>
                {cellComment && cellCommentVisible ? decodeURI(cellComment.text) : "Didn't say anything"}
            </div>
        </div>
        <div className={"social-controls"}>
            {/* <div className="chatButton">
                <BsFillChatTextFill />
            </div> */}
            <Button
                aria-controls={open ? 'answers-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                id="social-menu"
                onClick={handleClick}
            >
                <IoMdMenu />
            </Button>
            <Menu
                id="answers-menu"
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                {
                    menuOptions.filter((o) => o !== undefined).map((option: MenuOption, index: number) => 
                        option.label === "" ?
                        <Divider sx={{ my: 0.5 }} key={`sb-menu-divider-${index}`} /> :
                        <MenuItem 
                            key={`sb-menu-option-${index}`} 
                            onClick={() => {
                                option.onClick();
                                handleClose();
                            }}
                            className="social-menu-item"
                            disabled={option.disabled}
                        >
                            <ListItemIcon>
                                {option.icon ? option.icon : null}
                            </ListItemIcon>
                            <ListItemText>
                                {option.label}
                            </ListItemText>
                        </MenuItem>
                    )
                }
            </Menu>
        </div>
      </div>
    );
  }
  
export default SocialBar;
  