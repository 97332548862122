
export const IS_DEV = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");

export const API_URL = IS_DEV ? 'http://localhost:8042' : 'https://crossword.kozymail.com';

export const SOCKET_URL = IS_DEV ? 'ws://localhost:8042' : 'wss://crossword.kozymail.com/crosswordws';

export const ANSWER_KEY = "_CROSSWORD_ANSWERS_KEY_";


export const IMAGE_PROXY = API_URL + "/game/image?url=";
export const IMO_PROXY = "https://imotbo.com/r2.php?proxy=";
export const IMO_PLAYER = "https://imotbo.com?play=";


export const KEYBOARD_LAYOUTS = [
    [
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "Z X C V B N M ◄",
        `REBUS 🖊️ ✏️`,
        // `📌 REBUS 🖊️ ✏️`,
    ],
    [
        // `📌 REBUS 🖊️ ✏️`,
        `REBUS 🖊️ ✏️`,
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "Z X C V B N M ◄",
    ],
    [
        // `📌 1 2 3 4 5 6 7 8 9 0 🖊️ ✏️`,
        `1 2 3 4 5 6 7 8 9 0 🖊️ ✏️`,
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "REB Z X C V B N M ◄",
    ],
];

export const FONTS = [
    "Arial",
    'Caveat', 
    'Chakra Petch',
    'Croissant One', 
    'Dancing Script',
    'Gloock', 
    'Julius Sans One', 
    'Nanum Pen Script',
    'Patrick Hand',
    'Press Start 2P',
    'Sedgwick Ave Display',
    'Penisland',
    'Softies',
    'Halloween',
    'Holiday',
    'SouthernPark',
];