import { Badge, Divider, Input, useTheme } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Answer, Chat, ClueAttachment } from "../pages/Game";
import "./ChatRoom.css";
import { BsFillSendFill, BsInboxFill } from "react-icons/bs";
import { IoOpenOutline } from "react-icons/io5";
import { addCrossOrigin, isOnlyEmoji, randomKey } from "../Util";
import { FONTS, IMO_PLAYER, IMO_PROXY } from "../Constants";
import { MdOutlineAttachFile } from "react-icons/md";
import Logo from "./Logo";
import { useSettings } from "../providers/SettingsProvider";
import { Clue } from "./ClueScroller";
import { IoShieldCheckmark } from "react-icons/io5";
const moment = require('moment');

interface Props {
    chatHistory: Chat[],
    onMessageSent: (message: string) => void,
    onActiveChat: (active: boolean) => void,
    currentUser: string, // id,not name
    isMobile?: boolean,
    onAttachmentClicked?: (clueId: number, direction: string) => void
    onRefetch: () => void,
    currentClue?: Clue,
    answers: Answer[],
}

const ChatRoom = ({ onMessageSent, onActiveChat, chatHistory, currentUser, isMobile, onAttachmentClicked, onRefetch, currentClue, answers}: Props) => {
    const [message, setMessage] = useState<string>("");
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const settings = useSettings();
    // create ref for input
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const chatHistoryDiv = document.querySelector(".chat-history");
        if (chatHistoryDiv) {
            chatHistoryDiv.scrollTop = chatHistoryDiv.scrollHeight;
        }
        // console.log(chatHistory[chatHistory.length-1]);

        addCrossOrigin();
    }, [chatHistory]);

    const getYtEmbed = (videoId: string)  => {
        return (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            width="100%"
            height="300px"
            src={`${IMO_PLAYER}https://www.youtube.com/embed/${videoId}`}
            frameBorder={0}
            allowFullScreen={true}
            key={randomKey()}
          ></iframe>
        );
      }
      
    const getImageEmbed = (imageUrl: string) => {
        return <img src={imageUrl} alt={imageUrl} width="100%" height="auto" key={randomKey()}/>;
      }

    const  getGphy = (giphyId: string) => {
        const arr = giphyId.split("-")
        giphyId = arr[arr.length-1];
        return <iframe src={`${IMO_PROXY}https://giphy.com/embed/${giphyId}`} frameBorder="0" width="100%" height="300px" allowFullScreen key={randomKey()} title="imoframe"></iframe>;
      }
      
    const getRedgifs = (redgifsId: string) => {
        return <iframe src={`${IMO_PLAYER}https://www.redgifs.com/ifr/${redgifsId}`} frameBorder="0" width="100%" height="300px" allowFullScreen key={randomKey()} title="imoframe"></iframe>;
      }

    const getGeneralLinkEmbed = (linkUrl: string): JSX.Element => {
        // Assuming the linkUrl is a general URL to an external site
        return (
          <div className="link-embed">
            <a href={linkUrl} target="_blank" rel="noreferrer" title={linkUrl}>{linkUrl.substring(0, 50)}</a>
          </div>
        );
      }

    const getReddit = (redditId: string) => {
        return (
            <div className="reddit-embed">
                <iframe src={`https://imotbo.com/?r=https://www.reddit.com/r/aww/comments/${redditId}/`} frameBorder="0" width="100%" height="300px" allowFullScreen key={randomKey()}title="imoframe"></iframe>
            </div>
        )
    }

    const getAsArray = (data: any) => {
    }

    const SUPPORTED_EMBEDS = [
        {
            regex: /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)|youtu\.be\/([a-zA-Z0-9_-]+)/gi,
            embed: getYtEmbed,
        },
        {
            regex: /https?:\/\/.*\.(?:png|jpg|jpeg|gif).*/gi,
            embed: getImageEmbed,
        },
        {
            regex: /https?:\/\/(?:www\.)?giphy\.com\/(?:gifs|media|clips)\/([a-zA-Z0-9-]+)/gi,
            embed: getGphy,
        },
        {
            regex: /https?:\/\/(?:www\.)?redgifs\.com\/watch\/([a-zA-Z0-9-]+.*)/gi,
            embed: getRedgifs,
        },
        {
            // reddit
            regex: /https?:\/\/(?:www\.)?reddit\.com\/r\/[a-zA-Z0-9]+\/comments\/([a-zA-Z0-9]+)\/[a-zA-Z0-9_-]+.*/gi,
            embed: getReddit,
        }
        // {
        //     regex: /https?:\/\/.*\.[a-zA-Z0-9]{2,}/gi,
        //     embed: getGeneralLinkEmbed,
        // },
    ];

    function findAndCreateEmbeds(text: string): React.ReactNode[] {
        const replacedText: React.ReactNode[] = [];
        SUPPORTED_EMBEDS.forEach((embed) => {
            let match: RegExpExecArray | null;
            let lastIndex = 0;
            while ((match = embed.regex.exec(text)) !== null) {
                const beforeText = text.substring(lastIndex, match.index);
                const afterText = text.substring(match.index + match[0].length);
                if (beforeText) {
                    replacedText.push(beforeText);
                }
                replacedText.push(<Divider />);
                replacedText.push(embed.embed(match[1] || match[0]));
                if (afterText) {
                    replacedText.push(afterText);
                }
                lastIndex = match.index + match[0].length;
            }
        });

        if (replacedText.length === 0) {
            if (text.charAt(0) === "[") {
                // convert to array
                text.replace("[", "").replace("]", "").split(",").forEach((t) => {
                    t.indexOf("http") === -1 && replacedText.push(
                        <div className={"chat-system-array"}>
                            {t === "null" ? "" : t}
                        </div>
                    );
                });
            } else {
                return [text];
            }
        }

        return replacedText;
    }


    const renderFormattedClue = (att: ClueAttachment) => {
        if (!att) return "";
        return (
            <div dangerouslySetInnerHTML={{__html: att.text.formatted?.replaceAll("https://", `${IMO_PROXY}https://`) || att.text.plain}}>
            </div>
        )
    };

    const renderClueAnswer = (clue: Clue, timestamp: string) => {
        return (
            <div className="clue-attachment-word">
                {clue.cells.map((cell, index) => 
                    <div className={"chat-clue-block"}
                        key={`clue-attachment-${index}`}
                        style={index > 0 ? {borderLeft: "0px solid black"} : {}}
                    >
                        
                        {
                            // Number(answers[cell].timestamp) <= Number(timestamp) && 
                            answers[cell]?.letter ? 
                            answers[cell]?.letter : " "
                        }
                    </div>
                )
             }
            </div>
        )
       
    }

    const renderHistory = () => {
        return  chatHistory.map((chat: Chat, index: number) => {
            const cls = chat.userId === currentUser ? "chat-message-container-me" : "chat-message-container";
            const emoji = isOnlyEmoji(chat.message);
                        return (
                <div 
                    className={chat.type === "system" ? "chat-message-system" : cls} 
                    key={`chat-${index}`}
                >
                    <div className={"chat-username"}>
                        {chat.type === "system" ? <div><Logo width={24} height={24} fill="#000000"/></div>: chat.username}
                    </div>
                    <div 
                        className={emoji ? "chat-message-emoji" : "chat-message"} 
                        style={{fontFamily: settings.settings.disableUserFonts ? FONTS[0] : FONTS[chat.fontIndex || 0],background: chat.color+"50", justifyContent: chat.userId === currentUser ? "flex-end" : "flex-start"}}
                    >
                        {findAndCreateEmbeds(decodeURI(chat.message))}
                    </div>
                    <div className={"chat-timestamp"}>
                        {
                            chat.verified ? 
                            <div className={"chat-verified"}><IoShieldCheckmark /> Verified</div> : null
                        }
                        {moment(Number(chat.timestamp)).startOf('second').fromNow()}
                    </div>
                    <div className={"chat-attachments"}>
                        {
                            chat.clueAttachments?.map((a, i) => {
                                return (
                                    <div 
                                        className={"chat-attachment"}
                                        key={`chat-attachment-inner-${i}`}
                                        onClick={() => {
                                            if (onAttachmentClicked) {
                                                onAttachmentClicked(a.clueIndex, a.direction);
                                            }
                                        }}
                                    >
                                        <div className={"chat-attachment-header"}>
                                            <div className={"chat-attachment-number"}>
                                                {a.clueLabel}{a.direction.charAt(0).toUpperCase()}</div>
                                            {/* @ts-ignore */}
                                            <div className={"chat-attachment-text"}>{renderFormattedClue(a)}</div>
                                            <div className={"chat-attachment-icon"}>
                                                <IoOpenOutline />
                                            </div>
                                        </div>
                                        <div
                                            className={"clue-attachment-answer"}
                                        >
                                            {renderClueAnswer(a.clue, chat.timestamp)}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        });
    }

    const hs = useMemo(renderHistory, [chatHistory]);

    const renderEmpty = () => {
        return (
            <div className={"chat-empty"}
                onClick={() => onRefetch()}
            >
                <BsInboxFill className={"chat-empty-icon"}/>
                <div className={"chat-empty-text"}>No messages yet</div>
                <div className={"chat-empty-subtext"}>Tap here to try again</div>
            </div>
        )
    }

    return (
        <div className={isMobile ? "chat-container-mobile" : "chat-container"} 
            style={isDarkMode ? {background: "#2f2f2f", border: "none", borderRadius: "0px"} : {}}
        >
            <div className={"chat-history"} 
            style={isDarkMode ? {background: "#2f2f2f"} : {}}
            >
                {hs.length === 0 ? renderEmpty() : hs}
            </div>
            <div className={"message-composer-attachment-header"}
                onClick={() => {
                    if(currentClue) {
                        const clue = `${currentClue.label}${currentClue.direction.charAt(0).toUpperCase()}`;
                        setMessage(message + " " + clue + " ");
                    }
                    // refocus on input
                    inputRef.current?.focus();
                    inputRef.current?.click();
                }}
            >
                <MdOutlineAttachFile /> Tap to append your current clue: {currentClue?.label}{currentClue?.direction.charAt(0).toUpperCase()}
            </div>
            <div className={"message-composer-container"}>
                <Input 
                    placeholder={"Type a message"}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            onMessageSent(message);
                            setMessage("");
                            localStorage.setItem("chat-read-time", Date.now().toString());
                        }
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                    onBlur={() => onActiveChat(false)}
                    onFocus={() => onActiveChat(true)}
                    disableUnderline
                    ref={inputRef}
                    endAdornment={
                        <>
                        
                        <div className={"message-composer-send"} onClick={() => {
                            if (message.length === 0) return;
                            onMessageSent(message);
                            setMessage("");
                            localStorage.setItem("chat-read-time", Date.now().toString());
                        }}>
                            <BsFillSendFill />
                        </div>
                        </>
                    }
                    value={message}
                    sx={{
                        color: "white",
                        padding: "5px",
                        paddingLeft: "10px",
                        width: "100%",
                    }}
                />
            </div>
        </div>
    )
}

export default ChatRoom;