import { useRouteError } from "react-router-dom";
import "./Error.css";
import { BiErrorAlt } from "react-icons/bi";
import { useState } from "react";

const Error = () => {
    const [taps, setTaps] = useState<number>(0);
    const error = useRouteError();
    return (
        <div className={"error-container"} onClick={() => setTaps(taps + 1)}>
            <BiErrorAlt className={"error-icon"}/>
            <div className={"error-message"}>
                {/* @ts-ignore */}
                {taps >= 5 ? error?.message || "No Error Message Available" : "Something went wrong. Please try again later."}
            </div>
        </div>
    )
}

export default Error;