import { useLayoutEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import { Button, Typography } from '@mui/material';
import "./Disconnected.css";
import Logo from '../components/Logo';
import { VERSION } from '../Version';
import { useNavigate } from "react-router-dom";

const Disconnected = () => {
  const { game }: any = useLoaderData();
  const navigate = useNavigate();

    useLayoutEffect(() => {
        window.addEventListener("focus", () => {
            if (window.document.hasFocus()) {
                // window.location.href = `/${game}?version=${VERSION}`;
                navigate(`/${game}?version=${VERSION}`);
            }
        });
    }, []);

    return (
        <div className="disconnected-container">
            <Logo width={150} height={150} fill='#ababab'/>
            <Typography variant="h5">Disconnected</Typography>
            <Typography variant="body1">You have been disconnected from the server.</Typography>
            <Typography variant="body1">Attempting to reconnect....</Typography>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                    // window.location.href = `/${game}?version=${VERSION}`;
                    navigate(`/${game}?version=${VERSION}`);
                }}
            >Not Reconnecting? Tap Here</Button>
        </div>
    )
}

export default Disconnected;