import React, { createContext, useEffect, useState } from 'react';
import { randomColor, randomName } from '../Util';
import { AppBar, Dialog, Divider, IconButton, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Switch, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { HexColorPicker } from 'react-colorful';
import { VERSION } from '../Version';
import { FONTS } from '../Constants';
import { IoShieldCheckmark } from "react-icons/io5";
import { QrReader } from 'react-qr-reader';
import qr from '../assets/qrverify.png';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SettingsProviderProps {
    children: React.ReactNode;
    onChange?: (settings: SettingsType) => void;
}

type SettingsContextType = {
    settings: SettingsType;
    updateSetting: (setting: any, value: any) => void;
    showSettingsWindow: () => void;
    open: boolean;
};

type SettingsType = {
    isDefault: boolean;
    theme: string;
    username: string;
    color: string;
    identifyUserAnswers: boolean; // Makes the users answers match their profile color
    showLocationOfOfflineUsers: boolean;
    holdCursorOnCellWhenDelete: boolean; // If true, when you delete a cell, the cursor will stay on that cell. If false, the cursor will move to the next cell.
    fontIndex: number;
    keyboardLayout: 0 | 1 | 2; // 0 = default, 1 = control buttons on top, 2 = undefined
    disableUserFonts: boolean;
    keepScreenAwake?: boolean;
    followCursorOnZoom?: boolean;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);



const SettingsProvider = ({children, onChange}: SettingsProviderProps) => {
    const [open, setOpen] = useState(false);
    const [qrOpen, setQrOpen] = useState(false);
    const [counter, setCounter] = useState(1);
    const [settings, setSettings] = useState<SettingsType>({
        isDefault: true,
        theme: "light",
        username: localStorage.getItem("username") || randomName(),
        color: localStorage.getItem("color") || randomColor(),
        identifyUserAnswers: false,
        holdCursorOnCellWhenDelete: false,
        showLocationOfOfflineUsers: false,
        fontIndex: 0,
        keyboardLayout: 0,
        disableUserFonts: false,
        keepScreenAwake: false,
        followCursorOnZoom: false,
    });

    useEffect(() => {
        const settings = localStorage.getItem("settings");
        if (settings) {
            setSettings(JSON.parse(settings));
            onChange && onChange(JSON.parse(settings));
        }
    }, []);

    useEffect(() => {
        if (settings.isDefault) return; // only save settings if they are not default
        localStorage.setItem("settings", JSON.stringify(settings));
        onChange && onChange(settings);
    }, [settings]);

    const updateSetting = (setting: any, value: any) => {
        const newSettings = {...settings};
        newSettings.isDefault = false;
        // @ts-ignore
        newSettings[setting] = value;
        setSettings(newSettings);
        // console.log(newSettings);
    }

    const showSettingsWindow = () => {
        setOpen(true);
    }
 
    const settingsWindow = () => {
        return (
          <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative', paddingTop: "calc(var(--sat))"}}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Settings
                </Typography>
              </Toolbar>
            </AppBar>
            <List
                style={{
                    overflowY: "scroll",
                    paddingBottom: "calc(var(--sab))",
                }}
            >
              <ListItem>
                {/* Username configuration */}
                <ListItemText primary="Username" />
                <Input
                  value={settings.username}
                  onChange={(e) => updateSetting("username", e.target.value)}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Profile color" secondary={'The color of your selected cells, chat bubbles, user circle, etc'}/>
              </ListItem>
              <ListItem 
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
              >
                <HexColorPicker color={settings.color} onChange={(c) => {updateSetting("color", c)}} />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Font" secondary={"Select the font you would like to use.  Everyone will see your answers in your font"} />
                <Select
                    value={settings.fontIndex}
                    label="Handwriting Font"
                    variant='standard'
                    onChange={(e) => updateSetting("fontIndex", e.target.value)}
                >
                    {
                        FONTS.map((font, index) => {
                            return (
                                <MenuItem key={index} value={index} style={{fontFamily: font}}>{font.toUpperCase()}</MenuItem>
                            )
                        })
                    }
                </Select>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Disable User Fonts" secondary={"If enabled, all user answers will be rendered in your font for you personally. Everyone else will still see custom user fonts."} />
                <Switch
                  checked={settings.disableUserFonts}
                  onChange={(e) => {
                    updateSetting("disableUserFonts", e.target.checked);
                  }}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Dark Theme" secondary={"If enabled, dark theme will be used"} />
                <Switch
                  checked={settings.theme === "dark"}
                  onChange={(e) => {
                    updateSetting("theme", e.target.checked ? "dark" : "light");
                  }}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Follow Cursor on Zoom" secondary={"If enabled, when you zoom in, the board will follow your cursor"} />
                  <Switch
                    checked={settings.followCursorOnZoom === true}
                    onChange={(e) => {
                      updateSetting("followCursorOnZoom", e.target.checked);
                    }}
                /> 
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Keep Screen Awake" secondary={"If enabled, your screen will not turn off while you are on the puzzle."} />
                <Switch
                  checked={settings.keepScreenAwake === true}
                  onChange={(e) => {
                    updateSetting("keepScreenAwake", e.target.checked);
                  }}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Use ink colors" secondary={"If enabled, answers entered will show up as the 'pen' (profile) color of the user.  This change only applies locally.  Other players will see black/colored pens based on their personal configurations."} />
                <Switch
                  checked={settings.identifyUserAnswers}
                  onChange={(e) => {
                    updateSetting("identifyUserAnswers", e.target.checked);
                  }}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Stay on Cell When Delete" secondary={"If enabled, when you delete a cell, the cursor will stay on that cell. If disabled, the cursor will move to the next cell."} />
                <Switch
                  checked={settings.holdCursorOnCellWhenDelete}
                  onChange={(e) => {
                    updateSetting("holdCursorOnCellWhenDelete", e.target.checked);
                  }}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Show Location of Offline Users" secondary={"If enabled, when a user is offline, their location will be shown on the board"} />
                <Switch
                  checked={settings.showLocationOfOfflineUsers}
                  onChange={(e) => {
                    updateSetting("showLocationOfOfflineUsers", e.target.checked);
                  }}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Mobile Keyboard Layout" secondary={"Select the keyboard layout you would like to use"} />
                <Select
                  value={settings.keyboardLayout || 0}
                  variant='standard'
                  onChange={(e) => {
                    updateSetting("keyboardLayout", e.target.value);
                  }}
                >
                    <MenuItem value={0}>Default</MenuItem>
                    <MenuItem value={1}>Control Buttons on Top</MenuItem>
                    <MenuItem value={2}>Include Number Row</MenuItem>
                </Select>
              </ListItem>
              <Divider />
                {
                  localStorage.getItem("verified") === settings.username ?
                    <ListItem>
                      <ListItemIcon>
                        <IoShieldCheckmark style={{fill: "#53af4d", fontSize: "24px"}} />
                      </ListItemIcon>
                      <ListItemText primary="You've Been Verified!" secondary={"You will have a verified badge next to your name. yay."} />
                    </ListItem>
                  :
                  <ListItemButton onClick={() => {
                    setQrOpen(!qrOpen);
                  }}>
                    <ListItemIcon>
                      <IoShieldCheckmark style={{fill: "#dadada", fontSize: "24px"}} />
                    </ListItemIcon>
                    <ListItemText primary="Get Verified" secondary={"Scan Verification QR Code"} />

                  </ListItemButton>
                }

                {qrOpen && 
                  <ListItem>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                        backgroundColor: "black"
                      }}
                    >
                      <QrReader
                        scanDelay={300}
                        onResult={(result, error) => {
                          if (!!result) {
                            if (result.getText() === "dc583e8c184610faeb08ac4ef542c871") {
                              localStorage.setItem("verified", settings.username);
                            }
                            setQrOpen(false);
                          }
                        }}
                        containerStyle={{width: "100vw", height: "50vh"}}
                        videoStyle={{width: "100vw", height: "50vh"}}
                        constraints={{facingMode: "environment"}}
                      />
                    </div>
                  </ListItem>
                }
              <Divider />
              {
                counter % 10 === 0 && settings.username.toLowerCase() === "brad" && 
                <>
                <ListItem>
                  <div>
                    <img src={qr} alt="qr code" />
                  </div>
                </ListItem>
                <Divider />
                </>
              }
              <ListItem onClick={() => setCounter(counter + 1)}>
                <ListItemText primary="Crosswords Version" secondary={`You're on version ${VERSION}`} />
              </ListItem>
            </List>
          </Dialog>
        )
    }

    return (
        <SettingsContext.Provider value={{ settings, updateSetting, showSettingsWindow, open}}>
            {children}
            {settingsWindow()}
        </SettingsContext.Provider>
    );
}

export default SettingsProvider;

export const useSettings = () => {
    const context = React.useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
}


